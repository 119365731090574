import React, { Component } from 'react'
import { getUser } from '../actions/session'
import Switch from "react-switch";
import Axios from 'axios';
import { Notifications } from '../components/common/notification';
import { USER_UPDATE_SERVER_URL } from '../config/rest_endpoints';


class EditServerConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            User: getUser(),
            ip: '',
            port: '',
            mask: '',
            serverId: '',
            switched: true,
            isLoad: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggleSwitch = this.toggleSwitch.bind(this);
        this.onUpdateForm = this.onUpdateForm.bind(this)
        this.clearForm = this.clearForm.bind(this)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            ip: newProps.previousDetails.ip,
            port: newProps.previousDetails.port,
            mask: newProps.previousDetails.mask,
            serverId: newProps.previousDetails.id,
            switched: newProps.previousDetails.true
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleSwitch() {
        this.setState({ switched: !this.state.switched })
    }

    clearForm() {
        this.setState({
            ip: '',
            port: '',
            mask: '',
            switched: true
        })
    }

    onUpdateForm() {
        const {  ip, port, mask, switched, User, serverId } = this.state
        let userId = User.id
        var payLoad = {
            "ip": ip,
            "port": parseInt(port),
            "mask": parseInt(mask),
            "enabled": switched
        }
        this.setState({ isLoad: true })
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.put(`${USER_UPDATE_SERVER_URL}/${userId}/server/${serverId}/update`, payLoad, { headers }).then(res => {
            if (res) {
                console.log('res :>> ', res);
                this.setState({
                    msg: res.data.message,
                    isLoad:false
                })
                Notifications.notify(this.state.msg, "Updated", "success")
                this.props.close()
                this.props.serverListing()
            }
        }).catch(err => {
            const errRes = err.response.data
            this.setState({ isLoad: false })
            Notifications.notify(errRes.message, "Failed", "error")
        })
    }


    render() {
        const { title, ip, port, mask, switched, isLoad } = this.state
        return (
            <div>
                <div className="form-group">
                    <label >IP</label>
                    <input type="email" className="form-control" name="ip" value={ip} onChange={this.handleChange} placeholder="Enter company email" required />
                </div>
                <div className="form-group">
                    <label>Port</label>
                    <input type="number" className="form-control" name="port" value={port} onChange={this.handleChange} placeholder="Enter session" required />
                </div>
                <div className="form-group">
                    <label >Mask</label>
                    <input type="number" className="form-control" name="mask" value={mask} onChange={this.handleChange} placeholder="Enter session" required />
                </div>
                <div className="form-group mt-1">
                    <div className="row">
                        <div className="col-md-3 mt-1">
                            <label className="fs-14" >Enabled</label>
                        </div>
                        <div className="col-md-1 mr-3">
                            <Switch onChange={this.toggleSwitch} checked={switched} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 ml-auto">
                        <button type="submit" className="btn btn-light float-right" onClick={this.onUpdateForm} disabled={isLoad ? true : false} >Confirm <i className={isLoad ? "icon-spinner2 spinner ml-1" : 'icon-checkmark2 ml-1'}></i></button>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditServerConfig