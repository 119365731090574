import React, { Component } from 'react'
import Container from '../components/common/application_container';
import CdrBalanceTable from './cdrBalanceTable';

export class CdrBalanceLogs extends Component {
    render() {
        return (
            <Container icon="fas fa-money-check-alt" title="CDR Balance" subTitle="Table"  >
                <CdrBalanceTable />
            </Container>
        )
    }
}

export default CdrBalanceLogs
