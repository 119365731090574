import React from 'react'
import { Link } from 'react-router-dom';
import Container from '../../components/common/application_container';
import { CDR_BALANCE_LOGS } from '../../components/routers/RouteConstants';

const StripeSuccessfull = () => {
    return (
        <Container icon="icon-credit-card2" title="Stripe" subTitle="Card Saved" >
            <div class="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                <button type="button" class="close" data-dismiss="alert"><span>×</span></button>
                <span class="font-weight-semibold">Important !</span> Your card successfully saved you can use it for future payment. <Link to={CDR_BALANCE_LOGS}>Use card now</Link>
            </div>
        </Container>
    )
}

export default StripeSuccessfull