import React from 'react'
import OutBoundStats from './outBoundStats'
import Container from '../common/application_container'
import InboundStats from './inboundStats'
import DiDStats from './didStats'


const StatsIndex = () => {
    return (
        <Container icon="icon-file-stats" title="Stats" subTitle="Table">
            <div class="card">
                <div class="card-body">
                    <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
                        <li class="nav-item"><a href="#bordered-justified-tab1" class="nav-link active" data-toggle="tab"><i class="icon-phone-outgoing icon-2x"></i><h4><b>Outbound</b></h4></a></li>
                        <li class="nav-item"><a href="#bordered-justified-tab2" class="nav-link" data-toggle="tab"><i class="icon-phone-incoming  icon-2x"></i><h4><b>Inbound</b></h4></a></li>
                        <li class="nav-item"><a href="#bordered-justified-tab3" class="nav-link" data-toggle="tab"><i class="icon-notebook icon-2x"></i><h4><b>DIDS</b></h4></a></li>
                    </ul>
                    <div class="tab-content card card-body border-top-0 rounded-0 rounded-bottom mb-0">
                        <div class="tab-pane fade active show" id="bordered-justified-tab1">
                            <OutBoundStats />
                        </div>
                        <div class="tab-pane fade" id="bordered-justified-tab2">
                            <InboundStats />
                        </div>
                        <div class="tab-pane fade" id="bordered-justified-tab3">
                            <DiDStats />
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default StatsIndex