import React, { Component } from 'react'
import LineGraph from '../common/graphs/lineGraph'
import DatePicker from "react-datepicker";
import Axios from 'axios';
import { getUser } from '../../actions/session';
import { MULTI_STATS_LINE_GRAPH } from '../../config/rest_endpoints';
import { now } from 'moment';
import { RC_LOAD_LOGIN } from '../routers/RouteConstants';
import { Notifications } from '../common/notification';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';


export class MultiStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dates: [],
            tcc:[],
            tcd: [],
            tdc: [],
            tccTolal:'',
            tcdTotal:'',
            tdcTotal:'',
            User:getUser(),
            date: new Date(),
            selectInterval: { value: 'week', label: 'week' },
            intervalOptions: [
                { value: 'week', label: 'week' },
                { value: 'fortnight', label: 'fortnight' },
                { value: 'half-year', label: 'half-year' },
                { value: 'year', label: 'year' }
            ],
            isExpire: false
        }
        this.handleSelectedInterval = this.handleSelectedInterval.bind(this)
        this.getAllStats = this.getAllStats.bind(this)
    }

    componentDidMount() {
        this.getAllStats()
    }

    handleSelectedInterval(selectInterval) {
        this.setState({ selectInterval},()=> {
            this.getAllStats()
        })
    }

    getAllStats() {
        const { User,selectInterval } = this.state
        var payload = {
            "statsDur": selectInterval.value
        }
        var userId = User.id
        var headers = { "Authorization": "Bearer " + User.token };
        console.log(`User.token`, User.token)
        Axios.post(`${MULTI_STATS_LINE_GRAPH}/${userId}/stats`,payload, { headers }).then(res => {
            if (res) {
                const resp = res.data.data
                console.log('respp :>> ', resp);
                const sumTCC = resp.totalCallCost.reduce((a,b)=>a+b,0)
                const sumTCD = resp.totalCallDuration.reduce((a, b) => a + b, 0)
                const sumTDC = resp.totalDialedCalls.reduce((a, b) => a + b, 0)

                this.setState({
                    dates:resp.date,
                    tcc: resp.totalCallCost,
                    tcd: resp.totalCallDuration,
                    tdc: resp.totalDialedCalls,
                    tccTolal:sumTCC,
                    tcdTotal:sumTCD,
                    tdcTotal:sumTDC
                })
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.setState({ isExpire: true })
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    render() {
        if(this.state.isExpire === true) {
            return <Redirect to={RC_LOAD_LOGIN} />
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card border-left-dark-alpha border-right-dark-alpha">
                        <div className="card-header header-elements-sm-inline py-sm-1">
                            <h3 className="card-title mr-auto "><h5><b>Call Stats</b></h5></h3>
                            <div className="header-elements">

                                <div className="form-group mt-2">
                                    <div class="input-group">
                                    <Select
                                        value={this.state.selectInterval}
                                        onChange={this.handleSelectedInterval}
                                        options={this.state.intervalOptions}
                                        className="width-27x"
                                    />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="row text-center">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <h5 className="font-weight-semibold mb-0">{this.state.tccTolal}</h5>
                                        <span className="text-muted fs-14">Sum of Total Call Cost</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <h5 className="font-weight-semibold mb-0">{this.state.tcdTotal}</h5>
                                        <span className="text-muted fs-14">Sum of Total Call Duration</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <h5 className="font-weight-semibold mb-0">{this.state.tdcTotal}</h5>
                                        <span className="text-muted fs-14">Sum of Total Dailed Call</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chart mb-2" id="app_sales">
                            <div className="pl-4 pr-4 pt-2 pb-3">
                                <LineGraph width={700} height={300}  title={'TCC'} title1={'TCD'} title2={'TDC'}  x={this.state.dates} y={this.state.tcc} y1={this.state.tcd} y2={this.state.tdc}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        )
    }
}

export default MultiStats
