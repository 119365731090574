import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { getUser } from '../../actions/session';
import {INVOICE_DOWNLOAD_API, INVOICE_LIST_API } from '../../config/rest_endpoints';
import CustomLoader from '../common/loader';
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import { RC_LOAD_LOGIN } from '../routers/RouteConstants';
import { Notifications } from '../common/notification';
import { withRouter } from 'react-router';
import Select from 'react-select';
import Pagination from "react-js-pagination"



const InvoiceTable = (props) => {
    const [tableData, setTableData] = useState([])
    const [userInfo] = useState(getUser())
    const [isGetting, setIsGetting] = useState(false)
    const [isExpire, setisExpire] = useState(false)
    const [startDate, setStartDate] = useState(setHours(setMinutes(setSeconds(new Date(), 0), 0),0))
    const [endDate, setEndDate] = useState(setHours(setMinutes(setSeconds(new Date(), 59), 59),23))
    const [isChecked, setisChecked] = useState(false)
    const [activePages, setActivePages] = useState(1)
    const [totalCount,setTotalCount] = useState(null)
    const [itemCounts, setItemCounts] = useState({ label: '10', value: 10 })
    const [countList] = useState([
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'All', value: 400 },
    ])

    useEffect(() => {
        getInvoiceList()
    }, [activePages])

 
    const handleDate = (date, value) => {
        if (value == "start") {
            setStartDate(date)
        } else {
            setEndDate(date)
        }
    }

    const handleSelectPagination = (itemCounts) => {
        setItemCounts(itemCounts)
    }

    const handleChecked = () => {
        setisChecked(!isChecked)
    }


    const handlePageNumber = (pageNumber) => {
        setActivePages(pageNumber)
    }

    const getInvoiceList = () => {
        let User = userInfo
        var headers = { "Authorization": "Bearer " + User.token };
        var payload = {
            "startDate": startDate.toLocaleString(),
            "endDate": endDate.toLocaleString()
        }
        setIsGetting(true)
        Axios.post(`${INVOICE_LIST_API}${User.id}/list?excludeZero=${isChecked ? 'yes' : 'no'}&page=${activePages}&search=&limit=${itemCounts.value}`, payload, { headers }).then(res => {
            if (res) {
                let resp = res.data.data
                setTableData(resp)
                setIsGetting(false)
                setTotalCount(res.data.pageinfo.totalCount)
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                setisExpire(true)
                localStorage.removeItem('user');
                props.history.push(RC_LOAD_LOGIN);
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            Notifications.notify(alert.data.message, "Failed", "error")
            setIsGetting(false)

        })
    }

    const onDonwload = (data) => {
        // http://207.38.67.31:8080/invoice/6101874631813750e8e9b44d-000001.pdf  --- reference
        // http://207.38.67.31:8080/apiv1/invoice/61c1d30580ed4535d6a261d8-6101874631813750e8e9b44d-000001.pdf  --- reference
        const invoiceName = data.invoiceName
        const url = `${INVOICE_DOWNLOAD_API}/${invoiceName}`
        window.open(url, '_blank')

    }

    const renderTableData = () => {
        if (tableData === null || tableData.length === 0) {
            return (
                <div className="datatable-footer ml-3">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return tableData.map((row, idx) => {
                return (<tr key={idx}>
                    <td>{row['createDate']}</td>
                    <td>{row['frequency']}</td>
                    <td>{row['dateRange']}</td>
                    <td>{row['invoiceNumber']}</td>
                    <td>{row['inboundUsage']}</td>
                    <td>{row['inboundCost']}</td>
                    <td>{row['outboundMinutes']}</td>
                    <td>{row['outboundCost']}</td>
                    <td>{row['didsCount']}</td>
                    <td>{row['didsCost']}</td>
                    <td>{row['totalAmount']}</td>
                    <td><button type="button" class="btn btn-outline-dark btn-icon border-2 ml-2" onClick={onDonwload.bind(this, row)} ><i class={'icon-file-pdf'}></i></button></td>
                </tr>)
            })
        }
    }


    return (
        <div className="mt-2">
            <div className="card mb-2 mt-2 border-0 " >
                {/* <div className="header-elements float-right">
                    <div className="form-group ml-4" style={{ width: '9em' }}>
                        <Select
                            value={itemCounts}
                            onChange={handleSelectPagination}
                            options={countList}
                            placeholder="Select item count"
                        />
                    </div>
                </div> */}
                <div className="header-elements mt-4 ml-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <label className="fs-14">Start Date<span className="text-muted "> UTC Time-zone</span> </label>
                                <div class="input-group">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => handleDate(date, "start")}
                                        className="reporting-theme-date w-27em"
                                        includeTimes={[
                                            setHours(setMinutes(setSeconds(new Date(), 0), 0), 0),
                                        ]}
                                        showTimeSelect
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                                        isClearable
                                        placeholderText="Add date"
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <label className="fs-14">End Date<span className="text-muted "> UTC Time-zone</span> </label>
                                <div class="input-group">
                                    <DatePicker
                                        name="end"
                                        selected={endDate}
                                        onChange={date => handleDate(date, "end")}
                                        className="reporting-theme-date w-27em"
                                        includeTimes={[
                                            setHours(setMinutes(setSeconds(new Date(), 59), 59), 11),
                                        ]}
                                        showTimeSelect
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                                        isClearable
                                        placeholderText="Add date"
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 mt-1'>
                            <div className="form-group">
                                <label class="custom-control  custom-checkbox mt-4">
                                    <input type="checkbox" class="custom-control-input" style={{ color: '-internal-light-dark(black, white)' }} onChange={handleChecked} checked={isChecked} />
                                    <span class="custom-control-label">Exclude $0.00</span>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-2 '>
                            <div className="form-group">
                                <button type="submit" className="btn bg-light border " style={{marginTop:'2em'}} onClick={getInvoiceList} >Generate <i class="icon-arrow-right8 ml-2"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive ">
                        <table className="table  table-striped datatable-basic bg-light border dataTable no-footer">
                            <thead>
                            <tr className="left_align bg-dark">
                                <th>Create Date</th>
                                <th>Frequency</th>
                                <th>Date Range</th>
                                <th>Invoice Name</th>
                                <th>Inbound Usage</th>
                                <th>Inbound Cost</th>
                                <th>Outbound Minutes</th>
                                <th>Outbound Cost</th>
                                <th>DIDs Count</th>
                                <th>DIDs Cost</th>
                                <th>Total Cost</th>
                                <th>Download Report</th>
                            </tr>
                            </thead>
                            <tbody>
                            {isGetting ? <CustomLoader /> : renderTableData()}
                            </tbody>
                        </table>
                    </div>
                    <div className="datatable-footer">
                        <div className="mt-3 text-center">
                            <Pagination
                                activePage={activePages}
                                itemsCountPerPage={itemCounts.value}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                onChange={handlePageNumber}
                            />
                        </div>
                        {/* <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to {tableLength} of {tableLength}</div> */}
                        <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(InvoiceTable)