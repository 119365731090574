import React, { Component } from 'react'
import Axios from 'axios';
import { getUser } from '../actions/session';
import { Notifications } from '../components/common/notification';
import { USER_DELETE_SERVER_URL } from '../config/rest_endpoints';


class DeleteServerConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            User: getUser(),
            serverName: '',
            serverId: '',
            ip: '',
            port: '',
            mask: '',
            status: '',
            msg: '',
            isLoad: false

        }
        this.deleteServer = this.deleteServer.bind(this)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            serverName: newProps.deleteDetails.serverTitle,
            serverId: newProps.deleteDetails.id,
            Ip: newProps.deleteDetails.ip,
            port: newProps.deleteDetails.port,
            mask: newProps.deleteDetails.mask,
            status: newProps.deleteDetails.active
        })

    }

    deleteServer() {
        const { serverId, User } = this.state
        let userId = User.id
        var headers = { "Authorization": "Bearer " + User.token };
        this.setState({ isLoad: true })
        Axios.delete(`${USER_DELETE_SERVER_URL}/${userId}/server/${serverId}/delete`, { headers }).then(res => {
            if (res) {
                this.setState({
                    msg: res.data.message,
                    isLoad:false
                })
                Notifications.notify(this.state.msg, "Updated", "success")
                this.props.close()
                this.props.serverListing()
            }
        }).catch(err => {
            const errRes = err.response.data
            this.setState({ isLoad: false })
            Notifications.notify(errRes.message, "Failed", "error")
        })
    }

    render() {
        const { serverName, isLoad } = this.state
        return (
            <div>
                               
                <div class="modal-body">
                    <h4>Are you sure want to delete {serverName} ? </h4>
                </div>
                <div class="modal-footer">
                    {/* <button type="button" class="btn btn-light" onClick={this.props.close} data-dismiss="modal">Close</button> */}
                    <button type="button" class="btn btn-light" onClick={this.deleteServer} disabled={isLoad ? true : false}>Confirm<i className={isLoad ? "icon-spinner2 spinner ml-1" : 'icon-checkmark2 ml-1'}></i></button>
                </div>
            </div>

        )
    }
}
export default DeleteServerConfig