import React, { Component } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

class MainIndex extends Component {
   constructor(props) {
      super(props);
      this.state = {
         userData: {},

      }
   }

   componentWillReceiveProps(newProps) {
      // console.log(newProps);
   }

   render() {
      const { session, CPS, balance } = this.props

      return (
         <div className="row">
            <div className="col-md-4">
               <div className="card card-body bg-teal text-white has-bg-image">
                  <div className="media mb-3">
                     <div className="media-body">
                        <h6 className="font-weight-semibold mb-0 fs-22">Sessions: {session.value}</h6>
                     </div>
                     <div className="ml-3 align-self-center">
                        <i class="icon-history font-s-36" />
                     </div>
                  </div>
                  <div className="mb-2">
                     <ProgressBar animated now={session.value} max={session.limit} variant="white" style={{ height: '0.5rem', backgroundColor: '#eeeeee69' }} />
                  </div>
                  <div className="fs-20">
                     <span className="float-right"><b>{session.limit}</b></span>
                     Limit
                  </div>
               </div>
            </div>
            <div className="col-md-4">
               <div className="card card-body bg-pink text-white has-bg-image">
                  <div className="media mb-3">
                     <div className="media-body">
                        <h6 className="font-weight-semibold mb-0 fs-22">CPS: {CPS.value}</h6>
                     </div>
                     <div className="ml-3 align-self-center">
                        <i class="icon-phone-wave font-s-36" />
                     </div>
                  </div>
                  <div className="mb-2">
                     <ProgressBar animated now={CPS.value} max={CPS.limit} variant="white" style={{ height: '0.5rem', backgroundColor: '#eeeeee69' }} />
                  </div>
                  <div className="fs-20">
                     <span className="float-right"><b>{CPS.limit}</b></span>
                     Limit
                  </div>
               </div>
            </div>
            <div className="col-md-4">
               <div className="card card-body bg-primary text-white has-bg-image">
                  <div className="media mb-3">
                     <div className="media-body">
                        <h6 className="font-weight-semibold mb-0 fs-22">Balance: {balance.value}</h6>
                     </div>
                     <div className="ml-3 align-self-center">
                        <i class="icon-users4 font-s-36" />
                     </div>
                  </div>
                  <div className="mb-2">
                     <ProgressBar animated now={balance.value} max={balance.limit} variant="white" style={{ height: '0.5rem', backgroundColor: '#eeeeee69' }} />
                  </div>
                  <div className="fs-20">
                     <span className="float-right"><b>{balance.limit}</b></span>
                     Limit
                  </div>
               </div>
            </div>
         </div>

      )
   }
}
export default MainIndex