import { isValidDict } from "./validator";

export const SESSION_USER_KEY = "customer"


export function saveUser(user) {
    if (isValidDict(user)) {
        localStorage.setItem(SESSION_USER_KEY, JSON.stringify(user));
        // localStorage.removeItem('logout')
    }
}

export function getUser() {
    let user = localStorage.getItem('customer');
    if (user) {
        console.log(user)
        return JSON.parse(user)
    }
    return null
}