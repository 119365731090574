import React, { Component } from 'react'
import { getUser } from '../../actions/session';
import DatePicker from "react-datepicker";
import CustomLoader from '../common/loader';
import Axios from 'axios';
import { INBOUND_STATS_API, OUTBOUND_STATS_API } from '../../config/rest_endpoints';
import { RC_LOAD_LOGIN } from '../routers/RouteConstants';
import { Notifications } from '../common/notification';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import moment from 'moment'
import { withRouter } from 'react-router';


class OutBoundStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            User: getUser(),
            data: [],
            startDate: setHours(setMinutes(setSeconds(new Date(), 0), 0), 0),
            endDate: setHours(setMinutes(setSeconds(new Date(), 59), 59), 23),
            isGetting: false,
            isLoad: false
        }
        this.getReportOnSelectDate = this.getReportOnSelectDate.bind(this)
        this.handleDate = this.handleDate.bind(this)

    }

    componentDidMount() {
        this.getReportOnSelectDate()
    }

    handleDate(date, value) {
        if (value === "start") {
            this.setState({ startDate: date }, () => {
            })
        } else {
            this.setState({ endDate: date }, () => {
            })
        }
    }

    getReportOnSelectDate() {
        const { User, startDate, endDate } = this.state
        var userId = User.id
        var payload = {
            "startDate": startDate.toLocaleString(),
            "endDate": endDate.toLocaleString()
        }
        console.log(`payload`, payload)
        this.setState({ isGetting: true, isLoad: true })
        var headers = { 'Authorization': "Bearer " + User.token }
        Axios.post(`${INBOUND_STATS_API}/${userId}`, payload, { headers }).then(res => {
            if (res) {
                console.log(`res`, res)
                var resp = res.data.data
                this.setState({ data: resp, isGetting: false, isLoad: false },()=> {
                })
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN);

                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            this.setState({ isGetting: false, isLoad: false })
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }


    sumOfNumbers(data) {
        console.log('data :>> ', data);
        if(data.length) {
            let val = data.reduce((a,b) => a+b)
            return Math.round((val + Number.EPSILON) * 100) / 100
        }
      return false
    }

    sumOfColumn() {
        const { data } = this.state
        let totalLocalCost = []
        let totalLocalDuration = [] 
        let totalInternalCost = [] 
        let totalInternalDuration = [] 
        let totalTFNCost = [] 
        let totalTFNDuration = []  
        let allTotalCalls = [] 
        let allTotalCost = [] 
        let allTotalCount = [] 
        let allTOtalDuration = [] 
        data.map(row => {
            totalLocalCost.push(row['localCost'])
            totalLocalDuration.push(row['localDuration'])
            totalInternalCost.push(row['internalCost'])
            totalInternalDuration.push(row['internalDuration'])
            totalTFNCost.push(row['tfnCost'])
            totalTFNDuration.push(row['tfnDuration'])
            allTotalCalls.push(row['totalCalls'])
            allTotalCost.push(row['totalCost'])
            allTotalCount.push(row['totalCount'])
            allTOtalDuration.push(row['totalDuration'])
        })
      
        return (
            <tr className="left_align bg-dark">
                <th>Total</th>
                <th><i class="icon-coin-dollar mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalLocalCost)}</th>
                <th><i class="icon-history mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalLocalDuration)}</th>
                <th><i class="icon-coin-dollar mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalInternalCost)}</th>
                <th><i class="icon-history mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalInternalDuration)}</th>
                <th><i class="icon-coin-dollar mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalTFNCost)}</th>
                <th><i class="icon-history mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(totalTFNDuration)}</th>
                <th><i class="icon-phone-wave mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(allTotalCalls)}</th>
                <th><i class="icon-coins mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(allTotalCost)}</th>
                <th><i class="icon-watch mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(allTotalCount)}</th>
                <th><i class="icon-history mr-1 icon-1x"></i>{!data.length ? 0 :this.sumOfNumbers(allTOtalDuration)}</th>
            </tr>
        )
    }


    renderReportTable() {
        const { data } = this.state
        if (!data.length) {
            return (
                <div className="ml-4 mt-2">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0 entries</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return data.map((data, idx) => {
                return (
                    <tr className="right_align" key={idx} >
                        <td><span className="text-success-600"></span> {moment.utc(data['createDate']).local().format('DD-MMM-YYYY')}</td>
                        <td><span className="text-success-600"></span>{data['localCost']}</td>
                        <td><span className="text-success-600"></span> {data['localDuration']}</td>
                        <td><span className="text-success-600"></span> {data['internalCost']}</td>
                        <td><span className="text-success-600"></span> {data['internalDuration']}</td>
                        <td><span className="text-success-600"></span> {data['tfnCost']}</td>
                        <td><span className="text-success-600"></span> {data['tfnDuration']}</td>
                        <td><span className="text-success-600"></span> {data['totalCalls']}</td>
                        <td><span className="text-success-600"></span> {data['totalCost']}</td>
                        <td><span className="text-success-600"></span> {data['totalCount']}</td>
                        <td><span className="text-success-600"></span> {data['totalDuration']}</td>
                    </tr>
                )
            })
        }
    }

   

    render() {
        const { startDate, endDate, isGetting, isLoad } = this.state
        return (
            <div className="card mb-2 mt-2 border-0 ">
                {/* <h3 className="card-title font-weight-bold ml-4 mt-4">User Stats</h3> */}
                <div className="card-header header-elements-inline">

                    <div className="header-elements">
                        <div class="d-flex justify-content-start rounded p-0 ">
                            <div class="border-left-1 border-white py-2 px-3">
                                <div class="form-group">
                                    <label className="fs-14">Start Date<span className="text-muted "> UTC Time-Zone</span> </label>
                                    <div class="input-group">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => this.handleDate(date, "start")}
                                            className="reporting-theme-date w-27em"
                                            maxDate={new Date()}
                                            includeTimes={[
                                                setHours(setMinutes(setSeconds(new Date(), 0), 0), -24),
                                            ]}
                                            showTimeSelect
                                            timeIntervals={15}
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            isClearable
                                            placeholderText="Add start date"
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class="border-left-1 border-white py-2 px-3">
                                <div class="form-group">
                                    <label className="fs-14">End Date <span className="text-muted "> UTC Time-Zone</span> </label>
                                    <div class="input-group">
                                        <DatePicker
                                            name="end"
                                            selected={endDate}
                                            onChange={date => this.handleDate(date, "end")}
                                            className="reporting-theme-date w-27em"
                                            maxDate={new Date()}
                                            includeTimes={[
                                                setHours(setMinutes(setSeconds(new Date(), 59), 59), 11),
                                            ]}
                                            showTimeSelect
                                            timeIntervals={15}
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            isClearable
                                            placeholderText="Add end date"
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class=" border-left-1 border-white py-2 px-3 rounded-right">
                                <div className="form-group" style={{ marginLeft: '-2em', marginTop: '2.2em' }}>
                                    <button type="submit" className="btn btn-light" onClick={this.getReportOnSelectDate} disabled={isLoad ? true : false} >Search <i className={isLoad ? "icon-spinner2 spinner ml-2" : 'icon-filter4 ml-2'}></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive ">
                        <table className="table  datatable-basic bg-light border dataTable no-footer">
                            <thead>
                                <tr className="left_align bg-dark">
                                    <th>Create Date</th>
                                    <th>Local Cost</th>
                                    <th>Local Duration</th>
                                    <th>Internal Cost</th>
                                    <th>Internal duration</th>
                                    <th>TFN Cost</th>
                                    <th>TFN Duration</th>
                                    <th>Total Calls</th>
                                    <th>Total Cost</th>
                                    <th>Total Count</th>
                                    <th>Total Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isGetting ? <CustomLoader /> : this.renderReportTable()}
                                {this.state.data.length > 1 ? this.sumOfColumn() : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default OutBoundStats