import React, { Component } from 'react'
import { getUser } from '../../actions/session';
import DatePicker from "react-datepicker";
import CustomLoader from '../common/loader';
import Axios from 'axios';
import { OUTBOUND_STATS_API } from '../../config/rest_endpoints';
import { RC_LOAD_LOGIN } from '../routers/RouteConstants';
import { Notifications } from '../common/notification';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import moment from 'moment'
import { withRouter } from 'react-router';


class OutBoundStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            User: getUser(),
            data: [],
            startDate: setHours(setMinutes(setSeconds(new Date(), 0), 0),0),
            endDate: setHours(setMinutes(setSeconds(new Date(), 59), 59),23),
            isGetting: false,
            isLoad: false
        }
        this.getReportOnSelectDate = this.getReportOnSelectDate.bind(this)
        this.handleDate = this.handleDate.bind(this)

    }

    componentDidMount() {
        this.getReportOnSelectDate()
    }

    handleDate(date, value) {
        if (value === "start") {
            this.setState({ startDate: date }, () => {
            })
        } else {
            this.setState({ endDate: date }, () => {
            })
        }
    }
   
    getReportOnSelectDate() {
        const { User, startDate, endDate } = this.state
        var userId = User.id
        var payload = {
            "startDate": startDate.toLocaleString(),
            "endDate": endDate.toLocaleString()
        }
        console.log(`payload`, payload)
        this.setState({ isGetting: true, isLoad: true })
        var headers = { 'Authorization': "Bearer " + User.token }
        Axios.post(`${OUTBOUND_STATS_API}/${userId}`, payload, { headers }).then(res => {
            if (res) {
                console.log(`res`, res)
                var resp = res.data.data
                this.setState({ data: resp, isGetting: false, isLoad: false },()=> {
                    this.sumOfColumn()
                })
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN);

                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            this.setState({ isGetting: false, isLoad: false })
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    sumOfNumbers(data) {
        console.log('data :>> ', data);
        if(data.length) {
            let val = data.reduce((a,b) => a+b)
            return Math.round((val + Number.EPSILON) * 100) / 100
        }
      return false
    }

    sumOfACD(min, outCall) {
        let totalMin = min.reduce((a, b) => a + b)
        let totalOutCall = outCall.reduce((a, b) => a + b)
        let ACD = totalMin * 60 / totalOutCall
        return Math.round((ACD + Number.EPSILON) * 100) / 100
    }

    sumOfASR(complete, outCall) {
        let totalComplete = complete.reduce((a, b) => a + b)
        let totalOutCall = outCall.reduce((a, b) => a + b)
        let ASR = (totalComplete / totalOutCall) * 100
        return Math.round((ASR + Number.EPSILON) * 100) / 100
    }

    sumOfSDP(dpCount, completed) {
        let totalSDPCount = dpCount.reduce((a, b) => a + b)
        let totalCompleted = completed.reduce((a, b) => a + b)
        let SDP = (totalSDPCount / totalCompleted) * 100
        return Math.round((SDP + Number.EPSILON) * 100) / 100
    }


    sumOfColumn() {
        const { data } = this.state
        let totalRevenue = []
        let totalMinutes = []
        let totalActualMinutes = []
        let totalCompleted = []  
        let totalOutCalls = [] 
        let totalSdCount = [] 
        let totalRejectedCapacity = [] 
        let totalIndeterminates = []
        let totalIntrastate = [] 
        let totalInterstates = [] 
        let totalDcnCount = [] 

        data.map(row => {
            totalRevenue.push(row['revenue'])
            totalMinutes.push(row['minutes'])
            totalActualMinutes.push(row['actualMinutes'])
            totalCompleted.push(row['completed'])
            totalOutCalls.push(row['outCalls'])
            totalSdCount.push(row['sdCount'])
            totalRejectedCapacity.push(row['rejectedForCapacity'])
            totalIndeterminates.push(row['indeterminateMinutes'])
            totalIntrastate.push(row['intrastateMinutes'])
            totalInterstates.push(row['interstateMinutes'])
            totalDcnCount.push(row['dcnCount'])
        })
      
        return (
            <tr className="left_align bg-dark" >
                <td><span className="text-success-600"></span>Total</td>
                <td><span className="text-success-600"></span><i class="icon-coin-dollar mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalRevenue)}</td>
                <td><span className="text-success-600"></span><i class="icon-history mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalMinutes)}</td>
                <td><span className="text-success-600"></span><i class="icon-pulse2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfACD(totalActualMinutes,totalOutCalls)}</td>
                <td><span className="text-success-600"></span><i class="icon-pulse2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfSDP(totalSdCount, totalCompleted)}</td>
                <td><span className="text-success-600"></span><i class="icon-pulse2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfASR(totalCompleted,totalOutCalls)}</td>
                <td><span className="text-success-600"></span><i class="icon-pie-chart mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalCompleted)}</td>
                <td><span className="text-success-600"></span><i class="icon-pie-chart mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalOutCalls)}</td>
                <td><span className="text-success-600"></span><i class="icon-pie-chart mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalSdCount)}</td>
                <td><span className="text-success-600"></span><i class="icon-pie-chart4 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalRejectedCapacity)}</td>
                <td><span className="text-success-600"></span><i class="icon-stats-bars2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalIndeterminates)}</td>
                <td><span className="text-success-600"></span><i class="icon-stats-bars2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalIntrastate)}</td>
                <td><span className="text-success-600"></span><i class="icon-stats-bars2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalInterstates)}</td>
                <td><span className="text-success-600"></span><i class="icon-stats-bars2 mr-1 icon-1x"></i>{!data.length ? 0 : this.sumOfNumbers(totalDcnCount)}</td>
            </tr>
        )
    }


    renderReportTable() {
        const { data } = this.state
        if (!data.length) {
            return (
                <div className="ml-4 mt-2">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0 entries</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return data.map((data, idx) => {
                return (
                    <tr className="right_align" key={idx} >
                        <td><span className="text-success-600"></span>{moment.utc(data['createDate']).local().format('DD-MMM-YYYY')}</td>
                        <td><span className="text-success-600"></span>{data['revenue']}</td>
                        <td><span className="text-success-600"></span>{data['minutes']}</td>
                        <td><span className="text-success-600"></span>{data['ACD']}</td>
                        <td><span className="text-success-600"></span>{data['SDP']}</td>
                        <td><span className="text-success-600"></span>{data['ASR']}</td>
                        <td><span className="text-success-600"></span>{data['completed']}</td>
                        <td><span className="text-success-600"></span>{data['outCalls']}</td>
                        <td><span className="text-success-600"></span>{data['sdCount']}</td>
                        <td><span className="text-success-600"></span>{data['rejectedForCapacity']}</td>
                        <td><span className="text-success-600"></span>{data['indeterminateMinutes']}</td>
                        <td><span className="text-success-600"></span>{data['intrastateMinutes']}</td>
                        <td><span className="text-success-600"></span>{data['interstateMinutes']}</td>
                        <td><span className="text-success-600"></span>{data['dcnCount']}</td>
                    </tr>
                )
            })
        }
    }


    render() {
        const { startDate, endDate, isGetting, isLoad } = this.state
        return (
            <div className="card mb-2 mt-2 border-0">
                {/* <h3 className="card-title font-weight-bold ml-4 mt-4">User Stats</h3> */}
                <div className="card-header header-elements-inline">

                    <div className="header-elements">
                        <div class="d-flex justify-content-start rounded p-0 ">
                            <div class="border-left-1 border-white py-2 px-3">
                                <div class="form-group">
                                    <label className="fs-14">Start Date<span className="text-muted "> UTC Time-Zone</span> </label>
                                    <div class="input-group">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => this.handleDate(date, "start")}
                                            className="reporting-theme-date w-27em"
                                            maxDate={new Date()}
                                            includeTimes={[
                                                setHours(setMinutes(setSeconds(new Date(), 0), 0), -24),
                                            ]}
                                            showTimeSelect
                                            timeIntervals={15}
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            isClearable
                                            placeholderText="Add start date"
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class="border-left-1 border-white py-2 px-3">
                                <div class="form-group">
                                    <label className="fs-14">End Date <span className="text-muted "> UTC Time-Zone</span> </label>
                                    <div class="input-group">
                                        <DatePicker
                                            name="end"
                                            selected={endDate}
                                            onChange={date => this.handleDate(date, "end")}
                                            className="reporting-theme-date w-27em"
                                            maxDate={new Date()}
                                            includeTimes={[
                                                setHours(setMinutes(setSeconds(new Date(), 59), 59), 11),
                                            ]}
                                            showTimeSelect
                                            timeIntervals={15}
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            isClearable
                                            placeholderText="Add end date"
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class=" border-left-1 border-white py-2 px-3 rounded-right">
                                <div className="form-group" style={{ marginLeft: '-2em', marginTop: '2.2em' }}>
                                    <button type="submit" className="btn btn-light" onClick={this.getReportOnSelectDate} disabled={isLoad ? true : false} >Search <i className={isLoad ? "icon-spinner2 spinner ml-2" : 'icon-filter4 ml-2'}></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive ">
                        <table className="table  datatable-basic bg-light border dataTable no-footer">
                            <thead>
                                <tr className="left_align bg-dark">
                                    <th >Create Date</th>
                                    <th >Cost</th>
                                    <th >Minutes</th>
                                    <th >ACD</th>
                                    <th >SDP</th>
                                    <th >ASR</th>
                                    <th >Completed</th>
                                    <th >Out Calls</th>
                                    <th >SD Count</th>
                                    <th >Rejected For Capacity</th>
                                    <th >Indeterminate Minutes</th>
                                    <th >Intra Minutes</th>
                                    <th >Inter Minutes</th>
                                    <th >DNC Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isGetting ? <CustomLoader /> : this.renderReportTable()}
                                {this.state.data.length > 1 ? this.sumOfColumn() : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default OutBoundStats