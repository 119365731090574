import React, { Component } from 'react'
import InvoiceTable from './invoiceTable'
import Container from '../common/application_container'

export default class InvoiceIndex extends Component {
    render() {
        return (
            <div>
                <Container title="Invoice" subTitle="Table">
                    <InvoiceTable/>
                </Container>
            </div>
        )
    }
}
