import React, { Component } from 'react'
import Container from '../components/common/application_container';
import Axios from 'axios';
import { SERVER_LISTING, SERVER_LISTING_URL } from '../config/rest_endpoints';
import { getUser } from '../actions/session';
import CustomLoader from '../components/common/loader';
import CustomModal from '../components/common/modal';
import AddServerConfig from './addServerCOnfig';
import EditServerConfig from './editServerConfig';
import DeleteServerConfig from './deleteServerConfig';
import Pagination from "react-js-pagination";
import { RC_LOAD_LOGIN } from '../components/routers/RouteConstants';
import { Notifications } from '../components/common/notification';


class ServerMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            User: getUser(),
            serverListing: [],
            isGetting: false,
            isAddModal: false,
            isEditModal: false,
            previousData: {},
            deleteData: {},
            isDeleteModal: false,
            activePage: 1,
            itemsCount: 10,
            totalCount: 0

        }
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.getServerListing = this.getServerListing.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        this.getServerListing()
    }

    closeAddModal() {
        this.setState({
            isAddModal: false
        })
    }

    openAddModal() {
        this.setState({
            isAddModal: true
        })
    }

    openEditModal(row) {
        this.setState({
            isEditModal: true,
            previousData: row
        })
    }

    closeEditModal() {
        this.setState({
            isEditModal: false
        })
    }

    closeDeleteModal() {
        this.setState({
            isDeleteModal: false,
        })
    }

    openDeleteModal(row) {
        this.setState({
            isDeleteModal: true,
            deleteData: row
        })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber
        }, () => {
            this.getServerListing()
        });
    }

    getServerListing() {
        const { User, itemsCount, activePage } = this.state
        let userId = User.id
        this.setState({
            isGetting: true
        })
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.get(`${SERVER_LISTING_URL}/${userId}/server?page=${activePage}&limit=${itemsCount}`, { headers }).then(res => {
            if (res) {
                var resp = res.data.data
                this.setState({
                    serverListing: resp,
                    isGetting: false,
                    totalCount: res.data.pageinfo.totalCount

                })

            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN);
                this.setState({ isExpire: true })
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            this.setState({ isGetting: false })
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    renderServerListing() {
        const { serverListing } = this.state
        var nullData = (
            <div className="ml-4 mt-2">
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0 entries</div>
                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
            </div>
        )
        if (serverListing == null) {
            return nullData
        }
        else {
            return serverListing.map((row, idx) => {
                return (
                    <tr key={idx}>
                        <td>{row.ip}</td>
                        <td>{row.mask}</td>
                        <td>{row.port}</td>
                        <td>{row.enabled === false ? <span className="badge badge-danger">True</span> : <span className="badge badge-success">False</span>}</td>
                        <td><i className="fa fa-edit mr-2" onClick={this.openEditModal.bind(this, row)} ></i><i className="fa fa-trash" onClick={this.openDeleteModal.bind(this, row)} aria-hidden="true" ></i></td>
                    </tr>
                )
            })
        }
    }

    render() {
        const { isGetting, isAddModal, isEditModal, isDeleteModal, previousData, deleteData, activePage, itemsCount, totalCount } = this.state
        let addServer = {
            details: {
                info: "Add Server",
                msg: <AddServerConfig serverListing={this.getServerListing} close={this.closeAddModal} />
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeAddModal },
            cancelBtn: { label: "Cancel", action: this.closeAddModal }
        }
        let editServer = {
            details: {
                info: "Update Server",
                msg: <EditServerConfig previousDetails={previousData} serverListing={this.getServerListing} close={this.closeEditModal} />
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeEditModal },
            cancelBtn: { label: "Cancel", action: this.closeEditModal }
        }
        let deleteServer = {
            details: {
                info: "Delete Server",
                msg: <DeleteServerConfig deleteDetails={deleteData} serverListing={this.getServerListing} close={this.closeDeleteModal} />
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeDeleteModal },
            cancelBtn: { label: "Cancel", action: this.closeDeleteModal }
        }
        return (
            <Container icon="fas fa-server" title="Server" subTitle="Table" >
                <CustomModal
                    visibility={isAddModal}
                    customBodyCSS={"h20"}
                    // modalType={"success"}
                    // modalHeader={'success'}
                    details={addServer.details}
                    showBtns={false}
                    cancelBtn={addServer.cancelBtn}
                    crossBtn={addServer.crossBtn}
                />
                <CustomModal
                    visibility={isEditModal}
                    // modalType={"success"}
                    // modalHeader={'success'}
                    details={editServer.details}
                    showBtns={false}
                    cancelBtn={editServer.cancelBtn}
                    crossBtn={editServer.crossBtn}
                />
                <CustomModal
                    visibility={isDeleteModal}
                    customBodyCSS={"h20"}
                    // modalType={"danger"}
                    // modalHeader={'danger'}
                    details={deleteServer.details}
                    showBtns={false}
                    cancelBtn={deleteServer.cancelBtn}
                    crossBtn={deleteServer.crossBtn}
                />
                <div className="card card-body">
                   
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                        <div className="datatable-header">
                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <input type="search" className="" placeholder="Type to filter..." aria-controls="DataTables_Table_0" />
                            </div>
                            <div className="header-elements">
                                <button type="submit" className="btn btn-light float-right" onClick={this.openAddModal} >Add Server <i className="fas fa-plus ml-2"></i></button>
                            </div>
                        </div>
                        <div className="datatable-scroll" style={{ overflowX: 'auto' }}>
                            <table className="table datatable-basic dataTable no-footer bg-light border" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead>
                                    <tr className="bg-dark">
                                        <th>IP Address</th>
                                        <th>Mask</th>
                                        <th>Port</th>
                                        <th>Enabled</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isGetting ? <CustomLoader /> : this.renderServerListing()}
                                </tbody>
                            </table>
                        </div>
                        <div className="datatable-footer mt-1 ">
                            <div className="mt-3">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCount}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

export default ServerMain
