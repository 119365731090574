import React, { Component, useReducer } from 'react';
import Container from './components/common/application_container';
import { getUser } from './actions/session';
import axios from 'axios';
import { Notifications } from './components/common/notification';
import {CALL_BALANCE_STATS_URL } from './config/rest_endpoints';
import 'react-circular-progressbar/dist/styles.css';
import { RC_LOAD_LOGIN } from './components/routers/RouteConstants';
import MainBalanceShow from './UserMain-page/mainIndex';
import CustomModal from './components/common/modal';
import { Redirect, withRouter } from 'react-router-dom';
import MultiStats from './components/MainPage/multiStats';
import TotalStats from './UserMain-page/totalStats';



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            User: getUser(),
            session: {},
            cps: {},
            amount: {},
            isLogout: false,
            isExpire: false
        }
        this.openLogOutModal = this.openLogOutModal.bind(this)
        this.closeLogOutModal = this.closeLogOutModal.bind(this)
    }

    openLogOutModal() {
        this.setState({ isExpire: true })
    }

    closeLogOutModal() {
        this.setState({ isExpire: false })
    }

    componentWillMount() {
        // this.userActiveList()
        // this.getCallCount()
        this.getUserDetails()
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.getUserDetails()
        }, 8000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getUserDetails() {
        const { User } = this.state
        var userId = User.id
        var headers = { "Authorization": "Bearer " + User.token };
        axios.get(`${CALL_BALANCE_STATS_URL}/${userId}/stats`, { headers }).then(res => {
            if (res) {
                var resp = res.data.data
                console.log('resp :>> ', resp);
                this.setState({
                    session: resp.sessions,
                    cps: resp.cps,
                    amount: resp.balance
                })
            }
        }).catch(err => {
            const alert = err.response
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN);
                this.setState({ isExpire: true })
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    render() {
        const { session, cps, amount,isExpire } = this.state
        if(this.state.isExpire === true) {
            return <Redirect to={RC_LOAD_LOGIN} />
        }
        let logoutModal = {
            details: {
                info: "Session Expire",
                msg:
                    <div className="row">
                        <div className="col-md-12">
                            <div class="alert alert-warning alert-styled-left alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert"></button>
                                <span class="font-weight-semibold">Your session!</span> has expire please <a href="#" class="alert-link">login again</a>.
							    </div>
                        </div>
                        <button type="submit" className="btn bg-danger mr-2 ml-auto" onClick={this.goLoginPage}><i class="icon-key mr-2"></i>Login </button>
                    </div>
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeLogOutModal },
            cancelBtn: { label: "Cancel", action: this.closeLogOutModal }
        }

        return (
            <Container icon="fa fa-home" title="Home" subTitle="Main" >
                 <CustomModal
                    visibility={isExpire}
                    customBodyCSS={"h20"}
                    modalType={"danger"}
                    modalHeader={"danger"}
                    details={logoutModal.details}
                    showBtns={false}
                    cancelBtn={logoutModal.cancelBtn}
                    crossBtn={logoutModal.crossBtn}
                />
                <div className="row">
                    <div className="col-md-12">
                        <MainBalanceShow session={session} CPS={cps} balance={amount} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                       <TotalStats />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <CdrStats />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CdrInboundStats />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-12">
                        <MultiStats />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <Balance balance={amount} />
                    </div>
                </div> */}
               
                {/* <div className="row">
                    <div className="col-md-12">
                        <TimeLineChart />
                    </div>
                </div> */}
            </Container>
        );
    }

}

export default withRouter(App) 
