import React from 'react'
import Container from '../../components/common/application_container';

const StripeSuccessPage = () => {
    return (
        <Container icon="icon-credit-card2" title="Stripe" subTitle="Card Saved" >
            <div class="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                <button type="button" class="close" data-dismiss="alert"><span>×</span></button>
                <span class="font-weight-semibold">Important !</span> Your stripe payment has been successful.
            </div>
        </Container>
    )
}

export default StripeSuccessPage