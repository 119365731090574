import React, { Component } from 'react'
import { getUser } from '../actions/session'
import Axios from 'axios'
import { Notifications } from '../components/common/notification';
import { Redirect, withRouter } from 'react-router-dom'
import { USER_CREATE_PAYMENT_URL } from '../config/rest_endpoints';

class PaymentAdd extends Component {
    constructor(props) {
        super(props)
        var user = getUser()
        this.state = {
            User: getUser(),
            payment: '',
            userId: user.id,
            urlLink: '',
            msg: '',
            isTrue: false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitPayment = this.submitPayment.bind(this)
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitPayment() {
        const { User, msg, payment, urlLink } = this.state
        var userId = User.id
        var payload = {
            "amount": parseFloat(payment),
            'type':2
        }
        console.log('payload :>> ', payload);
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${USER_CREATE_PAYMENT_URL}/${userId}/payment/paypal/create`, payload, { headers }).then(res => {
            if (res) {
                console.log('res payment :>> ', res);
                this.setState({
                    urlLink: res.data.data,
                    msg: res.data.message,
                    isTrue: true
                })
                // this.props.history.push(window.location.urlLink)
                var url = window.location
                console.log('url', url)

            }
            else {
                Notifications.notify('Failed in payment', "Failed", "error");
            }
        }).catch(err => {
            var errRes = err.response.data
            Notifications.notify(errRes.message, "Error", "error");

        })
    }

    render() {
        const { userId, payment, urlLink, isTrue } = this.state
        if (isTrue) {
            window.location.href = urlLink
        }
        return (
            <div>
                <div class="form-group row">
                    <label class="col-form-label col-lg-2 fs-14">Payment</label>
                    <div class="col-lg-10">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text user-group-color">$</span>
                            </span>
                            <input type="number" class="form-control" name="payment" value={payment} onChange={this.handleInputChange} placeholder="Payment" />
                            <span class="input-group-append">
                                <span class="input-group-text user-group-color">.00</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-3 ml-auto">

                        <button type="button" class="btn btn-light" onClick={this.submitPayment}>Pay Amount<i class="fab fa-paypal ml-2"></i></button>
                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(PaymentAdd)