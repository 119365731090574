export const RC_LOAD_APP = "/";
export const REDIRECT_URL = "/";

// auth bash constans
export const RC_AUTH_BASE_URL = "/auth";
export const RC_LOAD_LOGIN = `${RC_AUTH_BASE_URL}/login`;
export const RC_LOAD_LOGOUT = `${RC_AUTH_BASE_URL}/logout`;

export const SERVER_CONFIG = '/server'
export const CDR_LOGS = '/cdr-call'
export const CDR_BALANCE_LOGS = '/cdr-balance'
export const SUCCESS_PAYMENT_URL = '/successfull-payment'
export const REPORT_URL = '/cdr-call'
export const STATS = '/other-stats'
export const MAKE_PAYMENT_METHOD = '/payment-method'
export const STRIPE_CARD_SUCCESSFULL = '/stripe-saved'
export const STRIPE_PAGE_SUCCESSFULL = '/stripe-successfull'
export const USER_STATS = '/user-stats'
export const INVOICE = '/invoice'
// export const CDR_LOGS = `${REPORT_URL}`
// export const CDR_BALANCE_LOGS = `${REPORT_URL}/cdr-balance`
// export const ADDITIONAL_ACCOUNT_INFO = `/account-info`
// export const CARRIER = '/carrier'


export const RC_ERROR_404 = "/error404";

export const PAYMENT_SUCCESS_URL = "http://localhost:3000/stripe-saved";
