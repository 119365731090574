import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from '../stripeSetup/stripeForm';
import { CLIENT_AUTO_TOPOFF, CLIENT_CHARGE, CLIENT_DELETE, CLIENT_SAVE } from '../../config/rest_endpoints';
import { getUser } from '../../actions/session';
import Axios from 'axios';
import { Notifications } from '../../components/common/notification';


const stripePromise = loadStripe('pk_test_51HflW6Bt1iUVOQNmCzxfc2a1AdPjKHMHnCFOezRwXdTHsWCj3F6Cu4NaNzdahM8uTNk1Hw18QJMbeJmbuEwETbAP00RlNzmkF1');
const SavePaymentList = () => {
    const [selectPaymentType, setPaymentType] = useState('')
    const [paymentOption] = useState([
        { value: 'Save Credit Card', label: 'Save Credit Card' },
        { value: 'Credit Card List', label: 'Credit Card List' },
    ])

    const [state, setState] = useState({
        amount: 0
    })
    const [selectCard, setSelectCard] = useState('')
    const [cardOption, setCardOption] = useState([])
    const [isSubmitAmount, setisSubmitAmount] = useState(false)
    const [isDeleteConfirm, setisDeleteConfirm] = useState(false)
    const [isUpdate, setisUpdate] = useState(false)
    const [useTopoff, setuseTopoff] = useState(false)


    useEffect(() => {
        getListAgainstUser()
    }, [selectCard])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSelectedCard = (selectCard) => {
        console.log(`selectCard`, selectCard)
        setSelectCard(selectCard)
        setuseTopoff(selectCard['useForAutoTopoff'])
    }

    const handleTopoff = () => {
        setuseTopoff(!useTopoff)
    }

    const getListAgainstUser = () => {
        let User = getUser()
        console.log('User :>> ', User);
        const payload = { "accountID": User.id }
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${CLIENT_SAVE}`, payload, { headers }).then(res => {
            if (res) {
                let resp = res.data.data
                const list = resp.map((row) => {
                    row['label'] = row.cardLast4Digits
                    row['value'] = row.paymentMethodID
                    return row
                })
                setCardOption(list)
            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    const handlePaymentType = (selectPaymentType) => {
        console.log('selectPaymentType :>> ', selectPaymentType);
        setPaymentType(selectPaymentType)
    }

    const options = {
        // passing the client secret obtained in step 2
        clientSecret: window.location.pathname.split('/')[2],
        // Fully customizable with appearance API.
    }

    const renderSaveCreditCard = () => {
        return (
            <Elements stripe={stripePromise} options={options}>
                <SetupForm />
            </Elements>
        )
    }

    const renderCardList = () => {
        return (

            <div className="form-group mr-auto ml-4">
                <label className="fs-14 ">Card List</label>
                <Select
                    value={selectCard}
                    onChange={handleSelectedCard}
                    options={cardOption}
                    className="width-20x"
                />

            </div>
        )
    }

    const submitAmount = () => {
        let User = getUser()
        const payload = { "accountID": User.id, "paymentMethodID": selectCard.value, "amount": parseFloat(state.amount) }
        console.log(`payload`, payload)
        var headers = { "Authorization": "Bearer " + User.token };
        setisSubmitAmount(true)
        Axios.post(`${CLIENT_CHARGE}`, payload, { headers }).then(res => {
            if (res) {
                Notifications.notify(res.data.message, "Paid", "success")
                setState({
                    amount: 0
                })
                setisSubmitAmount(false)
            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
            setisSubmitAmount(false)
        })
    }

    const updateTopoff = () => {
        let User = getUser()
        const payload = {"paymentMethodID": selectCard['paymentMethodID'],"useForAutoTopoff":useTopoff}
        console.log(`payload`, payload)
        var headers = { "Authorization": "Bearer " + User.token };
        setisUpdate(true)
        Axios.put(`${CLIENT_AUTO_TOPOFF}`, payload, { headers }).then(res => {
            if (res) {
                console.log('res :>> ', res);
                Notifications.notify(res.data.message, "Topoff updated", "success")
                setSelectCard('')
                setisUpdate(false)
            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
            setisUpdate(false)
        })
    }

    const deleteCard = () => {
        let User = getUser()
        setisDeleteConfirm(true)
        const headers = { "Authorization": "Bearer " + User.token };
        Axios.delete(`${CLIENT_DELETE}?accountID=${User.id}&paymentMethodID=${selectCard.value}`, { headers }).then(res => {
            if (res) {
                let resp = res.data
                Notifications.notify(resp.message, "Deleted", "success")
                setisDeleteConfirm(false)
                setSelectCard('')
            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
            setisDeleteConfirm(false)
        })
    }

    const renderSelectedCard = () => {
        return (
            <div>
                <h4>Payment Information</h4>
                {Object.keys(selectCard).length ?
                    <div className="row">
                        <div className="col-md-6">
                            <label className="fs-14 ">Card Details</label>
                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input type="text" class="form-control" placeholder="Enter card number" disabled={true} required="" value={selectCard.label} />
                                <div class="form-control-feedback form-control-feedback-lg">
                                    <i class="icon-credit-card2"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="fs-14 ">Enter Amount</label>
                                <div class="form-group form-group-feedback form-group-feedback-left">
                                    <input type="number" class="form-control" name="amount" placeholder="Enter amount" required="" onChange={handleInputChange} value={state.amount} />
                                    <div class="form-control-feedback form-control-feedback-lg">
                                        <i class="icon-cash3"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <label class="custom-control custom-control-secondary custom-checkbox mb-2">
                                <input type="checkbox" class="custom-control-input" onChange={handleTopoff} checked={useTopoff} />
                                <span class="custom-control-label">Use For AutoTopoff</span>
                            </label>
                        </div>
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <button type="submit" className="btn btn-light float-right" disabled={isSubmitAmount ? true : false} onClick={submitAmount}>Pay Now<i className={isSubmitAmount ? "icon-spinner2 spinner ml-1" : 'icon-checkmark2 ml-1'}></i></button>
                            <button type="submit" className="btn btn-light" disabled={isUpdate ? true : false} onClick={updateTopoff}>Update<i className={isUpdate ? "icon-spinner2 spinner ml-1" : 'icon-pencil5 ml-1'}></i></button>
                            <button type="submit" className="btn btn-light" disabled={isDeleteConfirm ? true : false} onClick={deleteCard}>Delete<i className={isDeleteConfirm ? "icon-spinner2 spinner ml-1" : 'icon-trash ml-1'}></i></button>
                        </div>
                    </div>
                    : <p>Select any card from card list</p>
                }
            </div>


        )
    }

    return (
        <div>
            <div className="card card-body">
                <h4>Payment Method</h4>
                <div className="card-header header-elements-inline">
                    <div className="form-group">
                        <label className="fs-14 ">Payment Type</label>
                        <Select
                            value={selectPaymentType}
                            onChange={handlePaymentType}
                            options={paymentOption}
                            className="width-24x"
                        />
                    </div>
                    {selectPaymentType.value === 'Credit Card List' ? renderCardList() : ''}
                </div>
            </div>
            <div className="card card-body">
                {selectPaymentType.value === 'Save Credit Card' ? renderSaveCreditCard() :
                    selectPaymentType.value === 'Credit Card List' ? renderSelectedCard() : <h4>Please select any method</h4>
                }
            </div>
        </div>
    )
}

export default SavePaymentList