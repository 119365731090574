import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { getUser } from '../actions/session'
import { Notifications } from '../components/common/notification'
import { RC_LOAD_LOGIN } from '../components/routers/RouteConstants'
import {CDR_STATS_URL, DOWNLOAD_CSV_FILE, INBOUNT_CALL_COUNT} from '../config/rest_endpoints'
import {Link, withRouter} from "react-router-dom";

const TotalStats = (props) => {
    const [userInfo] = useState(getUser())
    const [outBoundStats, setOutBoundStats] = useState({})
    const [inBoundStats, setInBoundStats] = useState({})
    const [didStats, setdidStats] = useState({})
    const [balance, setBalance] = useState({})
    const [inboundCallCount, setInboundCallCount] = useState(0)


    useEffect(() => {
        getAllStats()
        getInboundCallCount()
    }, [])

    const getAllStats = () => {
        var userId = userInfo.id
        console.log(`userId`, userId)
        var headers = { "Authorization": "Bearer " + userInfo.token };
        // `${CDR_STATS_URL}/${userId}/stats/12h`
        Axios.get(`${CDR_STATS_URL}/${userId}/dashboard`, { headers }).then(res => {
            if (res) {
                console.log('resp :>> ', res);
                const resp = res.data.data
                setOutBoundStats(resp.outboundDashboardStats)
                setInBoundStats(resp.inboundDashboardStats)
                setdidStats(resp.didStats)
                setBalance(resp.balance)
            }
        }).catch(error => {
            if (error) {
                console.log(error, 'err1')
                Notifications.notify(error, "Error", "Error");
            }
            else if (error === 401 || error === undefined) {
                localStorage.removeItem('user');
                this.setState({ isExpire: true })
                this.props.history.push(RC_LOAD_LOGIN);
                Notifications.notify('Token expire', "Logout", "Error");
            }
        })
    }

   const  handleDownload=(id)=> {
       var userId = userInfo.id
       console.log(`userId`, userId)
       var headers = { "Authorization": "Bearer " + userInfo.token };
        Axios.get(`${DOWNLOAD_CSV_FILE}?file=${id}`,{headers}).then(res=>{
            let content = res.headers['content-disposition']
            if(res) {
                var resp = res.data
                var short = content.split("filename=")[1]
                var filename = short.replace(/['"]+/g, '')
                const url = window.URL.createObjectURL(new Blob([resp]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${filename}.csv`)
                document.body.appendChild(link)
                link.click()
            }
        }).catch(err=>{
            const alert = err.response
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN)
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }



    const getInboundCallCount = () => {
        var userId = userInfo.id
        console.log(`userId`, userId)
        var headers = { "Authorization": "Bearer " + userInfo.token };
        // `${CDR_STATS_URL}/${userId}/stats/12h`
        Axios.get(`${INBOUNT_CALL_COUNT}/:accountID/stats`, { headers }).then(res => {
            if (res) {
                const resp = res.data.data
                setInboundCallCount(resp.data)
            }
        }).catch(err => {
            const alert = err.response
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                props.history.push(RC_LOAD_LOGIN);
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    const renderOutBoundStats = () => {
        return (
            <tr className="right_align">
                <td><span className="text-success-600"></span><i class="icon-coin-dollar mr-1 icon-1x"></i> {outBoundStats['spent']}</td>
                <td><span className="text-success-600"></span><i class="icon-coin-dollar mr-1 icon-1x"></i> {outBoundStats['yesterdaySpent']} </td>
                <td><span className="text-success-600"></span><i class="icon-align-left mr-1 icon-1x"></i> {outBoundStats['ASR']} </td>
                <td><span className="text-success-600"></span><i class="icon-align-center-horizontal mr-1 icon-1x"></i> {outBoundStats['ACD']} </td>
                <td><span className="text-success-600"></span><i class="icon-align-right mr-1 icon-1x"></i>{outBoundStats['SDP']} </td>
                <td><span className="text-success-600"></span><i className="icon-coins mr-1 icon-1x"></i><Link onClick={() => handleDownload(outBoundStats['ratedeckID'])}>{outBoundStats['rate'] === '' ? 'N/A' : outBoundStats['rate']}</Link></td>
            </tr>
        )
    }

    const renderInBoundStats = () => {
        return (
            <tr className="right_align">
                <td><span className="text-success-600"></span> <i class="icon-history mr-1 icon-1x"></i>{inBoundStats['localDuration']}</td>
                <td><span className="text-success-600"></span> <i class="icon-history mr-1 icon-1x"></i>{inBoundStats['tfnDuration']}</td>
                <td><span className="text-success-600"></span> <i class="icon-history mr-1 icon-1x"></i>{inBoundStats['internalDuration']}</td>
                <td><span className="text-success-600"></span> <i class="icon-coin-dollar mr-1 icon-1x"></i>{inBoundStats['localCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-coin-dollar mr-1 icon-1x"></i>{inBoundStats['tfnCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-coin-dollar mr-1 icon-1x"></i>{inBoundStats['internalCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-history mr-1 icon-1x"></i>{inBoundStats['totalDuration']}</td>
                <td><span className="text-success-600"></span> <i class="icon-phone-wave mr-1 icon-1x"></i>{inBoundStats['totalCalls']}</td>
                <td><span className="text-success-600"></span> <i class="icon-coins mr-1 icon-1x"></i>{inBoundStats['totalCost']}</td>
            </tr>
        )
    }


    const renderDIDDsStats = () => {
        return (
            <tr className="right_align">
                <td><span className="text-success-600"></span> <i class="icon-price-tag2 mr-1 icon-1x"></i>{didStats['localDIDCount']}</td>
                <td><span className="text-success-600"></span> <i class="icon-price-tag2 mr-1 icon-1x"></i>{didStats['tollFreeDIDCount']}</td>
                <td><span className="text-success-600"></span> <i class="icon-price-tag2 mr-1 icon-1x"></i>{didStats['internalDIDCount']}</td>
                <td><span className="text-success-600"></span> <i class="icon-price-tag mr-1 icon-1x"></i>{didStats['localCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-price-tag mr-1 icon-1x"></i>{didStats['tfnCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-price-tag mr-1 icon-1x"></i>{didStats['internalCost']}</td>
                <td><span className="text-success-600"></span> <i class="icon-coins mr-1 icon-1x"></i>{didStats['totalCost']}</td>
            </tr>
        )
    }

    return (
        <div className="card">
            <div className="card-header header-elements-sm-inline">
            </div>
            <div className="card-body d-sm-flex align-items-sm-center flex-sm-wrap" style={{justifyContent: 'space-evenly'}}>
            <div className="d-flex align-items-center mb-3 mb-sm-0">
                <h4><b>Inbound Sessions </b></h4>
                    <div className="ml-3 ">
                        {inboundCallCount > 0 ?
                            <h5 className="font-weight-semibold mb-0 " style={{fontSize: '22px', marginTop:'-0.3em'}}>{inboundCallCount} <span className="text-success font-size-sm font-weight-normal"><i className="icon-history ml-2 icon-2x" style={{ marginTop: '-0.3em'}} /></span></h5>
                            :
                            <h5 className="font-weight-semibold mb-0" style={{fontSize: '22px' , marginTop:'-0.3em'}}>{inboundCallCount} <span className="text-danger font-size-sm font-weight-normal"><i className="icon-history ml-2 icon-2x" style={{ marginTop: '-0.3em'}}  /> </span></h5>
                        }
                    </div>
                </div>
                <div className="d-flex align-items-center mb-3 mb-sm-0">
                <h4><b>Inbound CPS</b></h4>
                    <div className="ml-3 ">
                        {inboundCallCount > 0 ?
                            <h5 className="font-weight-semibold mb-0 " style={{fontSize: '22px', marginTop:'-0.3em' }}>{inboundCallCount} <span className="text-success font-size-sm font-weight-normal"><i className="icon-phone-wave ml-2 icon-2x" style={{ marginTop: '-0.4em'}} /></span></h5>
                            :
                            <h5 className="font-weight-semibold mb-0" style={{fontSize: '22px', marginTop:'-0.3em'}}>{inboundCallCount} <span className="text-danger font-size-sm font-weight-normal"><i className="icon-phone-wave ml-2 icon-2x" style={{ marginTop: '-0.4em'}}   /> </span></h5>
                        }
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <div className="card-header header-elements-inline " style={{ background: '#eee' }}>
                    <div className="mr-auto"><h5><b>Outbound Stats</b></h5></div>
                    <div className="">  <i class="icon-phone-outgoing icon-2x"></i></div>
                </div>
                <table className="table text-nowrap">
                    <thead>
                        <tr>
                            <th>Spent</th>
                            <th>Spent Yesterday</th>
                            <th>ASR</th>
                            <th>ACD</th>
                            <th>SDP</th>
                            <th>Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderOutBoundStats()}
                    </tbody>
                </table>
            </div>
            <div className="table-responsive" style={{ borderTop: '1px solid #dddddd' }}>
                <div className="card-header header-elements-inline " style={{ background: '#eee' }}>
                    <div className="mr-auto"><h5><b>Inbound Stats</b></h5></div>
                    <div className="">  <i class="icon-phone-incoming  icon-2x"></i></div>
                </div>
                <table className="table text-nowrap">
                    <thead>
                        <tr>
                            <th>Local Duration</th>
                            <th>TFN Duration</th>
                            <th>Internal Duration</th>
                            <th>Local Duration Cost</th>
                            <th>TFN Duration Cost</th>
                            <th>Internal Duration Cost</th>
                            <th>Total Duration</th>
                            <th>Total Calls</th>
                            <th>Total Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInBoundStats()}
                    </tbody>
                </table>
            </div>


            <div className="table-responsive" style={{borderTop: '1px solid #dddddd'}}>
                <div className="card-header header-elements-inline " style={{background: '#eee'}}>
                    <div className="mr-auto"><h5><b>DID Stats</b></h5></div>
                    <div className=""><i className="icon-address-book3 icon-2x"></i></div>
                </div>
                <table className="table text-nowrap">
                    <thead>
                    <tr>
                        <th>Local DIDs</th>
                        <th>TFN DIDs</th>
                        <th>Internal DIDs</th>
                        <th>Local DIDs Cost</th>
                        <th>TFN DIDs Cost</th>
                        <th>Internal DIDs Cost</th>
                        <th>Total DIDs Cost</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderDIDDsStats()}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default withRouter(TotalStats)
