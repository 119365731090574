import React from 'react'
import Container from '../../components/common/application_container';
import SavePaymentList from './savePaymentList';

const SavePaymentIndex = () => {
  
    return(
        <Container icon="fas fa-money-check-alt" title="Payment" subTitle="Method"  >
           <SavePaymentList  />
        </Container>
    )
}

export default SavePaymentIndex