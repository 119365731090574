import { RC_LOAD_APP, ACCOUNT_FILE, CARRIER, SERVER_CONFIG, CDR_LOGS, CDR_BALANCE_LOGS, REPORT_URL, STATS, USER_STATS, INVOICE } from '../routers/RouteConstants';


export var naver = {
    [RC_LOAD_APP]: {
        "/": {
            "id": "M_HOME",
            "name": "Home",
            "url": RC_LOAD_APP,
            "fa_icon": "fa fa-home"
        },
        "fa_icon": "fa fa-home"
    },
    [SERVER_CONFIG]: {
        "/": {
            "id": "SERVER_CONFIG",
            "name": "Server",
            "url": SERVER_CONFIG,
            "fa_icon": "fas fa-server"
        }
    },
    [CDR_LOGS]: {
        "/": {
            "id": "CDR",
            "name": "CDR Search",
            "url": CDR_LOGS,
            "fa_icon": "fas fa-clipboard-list"
        }
    },
    [CDR_BALANCE_LOGS]: {
        "/": {
            "id": "CDR-balance",
            "name": "Payments",
            "url": CDR_BALANCE_LOGS,
            "fa_icon": "fas fa-money-check-alt"
        }
    },
    [USER_STATS]: {
        "/": {
            "id": "USER_STATS",
            "name": "Stats",
            "url": USER_STATS,
            "fa_icon": "icon-file-stats"
        }
    },
    [INVOICE]: {
        "/": {
            "id": "INVOICE",
            "name": "Invoice",
            "url": INVOICE,
            "fa_icon": "icon-file-spreadsheet"
        }
    }
}
