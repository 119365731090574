import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter, Redirect } from 'react-router-dom'
import { login } from '../../actions/index';
import { Notifications } from '../common/notification';
import { RC_LOAD_APP } from '../routers/RouteConstants';
import { saveUser, getUser } from '../../actions/session';
import { isNonEmptyDict } from '../../actions/validator';
import { clientInfo } from '../..';
import Axios from 'axios';
import { USER_LOGIN_REDIRECT } from '../../config/rest_endpoints';


class Login extends Component {
    constructor(props) {
        super(props);
        let checkToken = null
        this.state = {
            userDetails: { username: "", password: "" },
            isLoad:false,
            clientID: clientInfo,
            User:getUser(),
            adminLoginData:{},
            tokenRemove:checkToken
        }
        
        this.chkUser = this.chkUser.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleValue = this.handleValue.bind(this)
    }

    componentWillMount() {
        const { clientID,tokenRemove} = this.state
        console.log('tokenRemove :>> ', tokenRemove);
        console.log('clientID :>> ', clientID);
        if(clientID.includes('token')) {
            console.log('22 :>> ', 22);
                // localStorage.removeItem('logout')
                this.accessByAdminId()
        }
    }

    accessByAdminId() {
        const { User,clientID } = this.state
            const payload = {
                "accountID":clientID[2]
            }
            this.setState({isRedirectLoader:true})
            const headers = { "Authorization": "Bearer " + clientID[4] };
            Axios.post(USER_LOGIN_REDIRECT,payload, { headers }).then(res => {
                if (res) {       
                    console.log(`resp`, res)
                    const userToken = res.headers.x_auth_token
                    const resp = res.data.data
                    this.setState({adminLoginData:resp})
                    this.props.login(resp, userToken);
                    window.location.reload()
                }
            }).catch(err => {
                const alert = err.response
                console.log(`alert.data.message`, alert.data.message)
                if (alert.data.message === 'invalid or expired jwt') {
                    localStorage.removeItem('user');
                    this.setState({isLoginURL:true,isRedirectLoader:false})
                    
                    Notifications.notify(alert.data.message, "Token Expire", "error")
                }
                Notifications.notify(alert.data.message, "Failed", "error")
            })
    }


    componentDidMount() {
        console.log('componentDidMount :>> ');

        // this.chkUser();
    }

    componentWillReceiveProps(newProps) {
        console.log('componentWillReceiveProps :>> ',newProps);
        
        let { activeUser } = newProps;
        if (isNonEmptyDict(activeUser)) {
            saveUser(activeUser)
            this.props.history.push(RC_LOAD_APP)
            Notifications.notify("Login Successfully", "Login", "success");
        }

    }

    chkUser() {
        let current_user = getUser()
        if (current_user) {
            this.props.history.push(RC_LOAD_APP);
            this.setState({ isLoad: false })
        }
    }

    handleValue(e) {
        let { name, value } = e.target;
        let { userDetails } = this.state;
        userDetails[name] = value

        this.setState({ userDetails })
    }

    clearForm() {
        let userDetails = { username: "", password: "" }
        this.setState({ userDetails })
    }

    onLogin() {
        this.setState({isLoad:true})            
        let { userDetails } = this.state;
        this.props.login(userDetails);
        let current_user = getUser()
        if(!current_user) {
            setTimeout(()=> {
            this.setState({isLoad:false})            
            },1000)
        }
    }

    render() {
        const { userDetails, isLoad } = this.state
        let { username, password } = userDetails

        return (
            <div className="bg-loginAuth">
                <div class="d-flex justify-content-center ">
                    <div class="user_card login-mt-16">
                        <div class="d-flex justify-content-center" style={{ borderBottom: '1px solid #ccc', marginBottom: '1em' }}>
                            <div class="brand_logo_container">
                                <div className="brand_logo">

                                    <img src={process.env.PUBLIC_URL + "/assets/images/login/logo.png"} style={{ width: '16em', marginTop: '2em' }} alt="Logo" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                        <h5 class="mb-0 light-black-color" >Login to your account</h5>
                        </div>

                        <div className="d-flex justify-content-center">
                        <span class="d-block text-muted ">Enter your credentials below</span>
                        </div>

                        <div class="d-flex justify-content-center form_container">
                            <form className="form_style">
                                <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-user"></i></span>
                                    </div>
                                    <input type="text" name="username" value={username} onChange={this.handleValue} class="form-control input_user fs-16" placeholder="username" />
                                </div>
                                <div class="input-group mb-2">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                                    </div>
                                    <input type="password" name="password" value={password} onChange={this.handleValue} class="form-control input_pass fs-16" placeholder="password" />
                                </div>
                                <div class="form-group">

                                </div>
                                <div class="d-flex justify-content-center mt-3 login_container">
                                    {/* <button type="button" name="button" onClick={this.onLogin} class="btn login_btn fs-16">Login</button> */}
                                    <button type="button" class="btn btn-primary light-green-theme btn-block fs-16" onClick={this.onLogin} disabled={isLoad ? true : false}>Sign in <i className={isLoad ? "icon-spinner2 spinner ml-2" :"icon-circle-right2 ml-2"}></i></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

function mapStateToProps({ activeUser }) {
    return { activeUser }
}

export default connect(mapStateToProps, { login })(withRouter(Login));