import React, { Component } from 'react'
import Container from '../components/common/application_container';
import CdrTable from './cdrTable';

class CdrMain extends Component {
    render() {
        return (
            <Container icon="fas fa-clipboard-list" title="CDR Call" subTitle="Table" >
                <CdrTable />
            </Container>
        )
    }
}
export default CdrMain