import React, { useState } from 'react'
import { getUser } from '../actions/session'
import Axios from 'axios'
import { Notifications } from '../components/common/notification';
import { Redirect, withRouter, useHistory } from 'react-router-dom'
import { LOGIN_URL, USER_CREATE_PAYMENT_STRIPE, USER_CREATE_PAYMENT_URL } from '../config/rest_endpoints';
import { RC_LOAD_LOGIN } from '../components/routers/RouteConstants';
import PaymentFromMain from './makeSavePayment/paymentFromMain';


const StripePayment = (props) => {
    const [state, setState] = useState({
        payment: ''
    })
    const [url, setUrl] = useState('')
    const [isLoad, setIsLoad] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isChecked, setIschecked] = useState(false)

    const handleChange = e => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleCheckAmount = () => {
        setIschecked(!isChecked)
    }

    const onAmountSubmit = () => {
        var userId = getUser()
        var payload = {
            "amount": parseFloat(state.payment),
        }
        setIsLoad(true)
        var headers = { "Authorization": "Bearer " + userId.token };
        Axios.post(`${USER_CREATE_PAYMENT_STRIPE}/${userId.id}/payment/stripe/create`, payload, { headers }).then(res => {
            if (res) {
                const resp = res.data
                const url = resp.data
                window.location.replace(url);
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                Notifications.notify(alert.data.message, "Token Expire", "error")
                props.history.push(LOGIN_URL)
            }
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    const payAmountForm = () => {
        return (
            <div>
                <div class="form-group row">
                    <label class="col-form-label col-lg-2 fs-14">Payment</label>
                    <div class="col-lg-10">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text user-group-color">$</span>
                            </span>
                            <input type="number" class="form-control" name="payment" value={state.payment} onChange={handleChange} placeholder="Payment" />
                            <span class="input-group-append">
                                <span class="input-group-text user-group-color">.00</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-3 ml-auto">
                        <button type="button" class="btn btn-light" onClick={onAmountSubmit} disabled={isLoad ? true : false} >Pay Amount<i className={isLoad ? "icon-spinner2 spinner ml-1" : 'fab fa-stripe ml-1'}></i></button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <label class="custom-control custom-control-secondary custom-checkbox mt-1 mb-3">
                <input type="checkbox" class="custom-control-input" onChange={handleCheckAmount} checked={isChecked} />
                <span class="custom-control-label">{isChecked ? 'Direct payment if unchecked ' : 'Use Save cards for payment if checked'}</span>
            </label>
            {isChecked ?
                <PaymentFromMain /> :
                payAmountForm()
            }
        </div>
    )
}

export default withRouter(StripePayment)