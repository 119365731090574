import React, { Component } from 'react'
import Axios from 'axios';
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { getUser } from '../actions/session';
import CustomLoader from '../components/common/loader';
import CustomModal from '../components/common/modal';
import PaymentAdd from './paymentAdd';
import { CLIENT_ID, USER_Balance_LOGS_URL, GET_TOPOFF_PAYMENT, CREATE_TOPOFF_PAYMENT } from '../config/rest_endpoints';
import Pagination from "react-js-pagination";
import { MAKE_PAYMENT_METHOD, RC_LOAD_LOGIN } from '../components/routers/RouteConstants';
import { Notifications } from '../components/common/notification';
import StripePayment from './stripePayment';
// import { withRouter } from 'react-router';
import { Link, withRouter } from 'react-router-dom';



var firstDate = new Date();
const startT = moment(firstDate.getTime()).subtract(20, 'hours')

export class CdrBalanceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            User: getUser(),
            tableData: [],
            searchValue: '',
            startDate: startT._d,
            endDate: new Date(),
            isGetting: false,
            selectedtype: 'all',
            isAddModal: false,
            callTypes: [
                { value: 'all', label: 'All' },
                { value: 'successful', label: 'Successfull' },
                { value: 'failed', label: 'Failed' }
            ],
            activePage: 1,
            itemsCount: 10,
            totalCount: 0,
            isStripeOpen: false,
            clientId: '',
            isTopoffChecked: true,
            amountCharged: 0,
            minimumCharged: 0,
            isSubmitTopoff: false
        }
        this.handleCallType = this.handleCallType.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.searchSpace = this.searchSpace.bind(this)
        this.getCdrBalance = this.getCdrBalance.bind(this)
        this.closeAddModal = this.closeAddModal.bind(this)
        this.openAddModal = this.openAddModal.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.closeStripeModal = this.closeStripeModal.bind(this)
        this.openStripeModal = this.openStripeModal.bind(this)
        this.gotoPaymentConfiguration = this.gotoPaymentConfiguration.bind(this)
        this.handleTopoff = this.handleTopoff.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.createTopoffValue = this.createTopoffValue.bind(this)

    }

    componentWillMount() {
        this.getTopoffValue()

    }

    componentDidMount() {
        this.getCdrBalance()
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleTopoff() {
        this.setState({
            isTopoffChecked: !this.state.isTopoffChecked
        })
    }

    closeAddModal() {
        this.setState({
            isAddModal: false
        })
    }

    openAddModal() {
        this.setState({
            isAddModal: true
        })
    }

    closeStripeModal() {
        this.setState({
            isStripeOpen: false
        })
    }

    openStripeModal() {
        this.setState({
            isStripeOpen: true
        })
    }


    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber
        }, () => {
            this.getCdrBalance()
        });
    }

    handleCallType(selectedtype) {
        this.setState({ selectedtype }, () => {
        })
    }

    searchSpace(e) {
        this.setState({ searchValue: e.target.value })
    }

    handleDate(date, value) {
        if (value == "start") {
            this.setState({ startDate: date }, () => {
            })
        } else {
            this.setState({ endDate: date }, () => {
            })
        }
    }

    getTopoffValue() {
        let User = getUser()
        const payload = { "accountID": User.id }
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${GET_TOPOFF_PAYMENT}`, payload, { headers }).then(res => {
            if (res) {
                let resp = res.data.data
                console.log('get topoff resp :>> ', resp);
                this.setState({
                    amountCharged: resp.amountToCharge,
                    minimumCharged: resp.minimumBalance,
                    isTopoffChecked: resp.autoTopOff
                })
            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    createTopoffValue(value) {
        const { minimumCharged, amountCharged } = this.state
        let User = getUser()
        var headers = { "Authorization": "Bearer " + User.token };
        this.setState({ isSubmitTopoff: true })
        if (value === 'disable') {
            let payload = { "accountID": User.id, "autoTopOff": false, "minimumBalance": parseFloat(minimumCharged), "amountToCharge": parseFloat(amountCharged) }
            console.log('payload :>> ', payload);
            Axios.post(`${CREATE_TOPOFF_PAYMENT}`, payload, { headers }).then(res => {
                if (res) {
                    let resp = res.data
                    console.log('res :>> ', res);
                    this.setState({ isSubmitTopoff: false })
                    Notifications.notify(resp.message, "Success", "success")
                }
            }).catch(err => {
                this.setState({ isSubmitTopoff: false })
                const alert = err.response
                Notifications.notify(alert.data.message, "Failed", "error")
            })
        }
        else {
            let payload = { "accountID": User.id, "autoTopOff": this.state.isTopoffChecked, "minimumBalance": parseFloat(minimumCharged), "amountToCharge": parseFloat(amountCharged) }
            console.log('payload :>> ', payload);
            Axios.post(`${CREATE_TOPOFF_PAYMENT}`, payload, { headers }).then(res => {
                if (res) {
                    let resp = res.data
                    console.log('res :>> ', res);
                    this.setState({ isSubmitTopoff: false })
                    Notifications.notify(resp.message, "Success", "success")
                }
            }).catch(err => {
                this.setState({ isSubmitTopoff: false })
                const alert = err.response
                Notifications.notify(alert.data.message, "Failed", "error")
            })
        }
    }

    gotoPaymentConfiguration() {
        let User = getUser()
        console.log('User :>> ', User);
        const payload = { "accountID": User.id }
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${CLIENT_ID}`, payload, { headers }).then(res => {
            if (res) {
                let resp = res.data.data
                this.props.history.push(`${MAKE_PAYMENT_METHOD}/${resp}`)

            }
        }).catch(err => {
            const alert = err.response
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }


    getCdrBalance() {
        const { User, startDate, endDate, searchValue, activePage, itemsCount } = this.state
        var userId = User.id
        var payLoad = {
            "startDate": Math.floor(startDate.getTime() / 1000.0),
            "endDate": Math.floor(endDate.getTime() / 1000.0)
        }
        console.log(`payLoad`, payLoad)
        this.setState({ isGetting: true })
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${USER_Balance_LOGS_URL}/${userId}/billing/reports/balance?page=${activePage}&limit=${itemsCount}`, payLoad, { headers }).then(res => {
            if (res) {
                console.log(`res`, res)
                this.setState({
                    tableData: res.data.data,
                    isGetting: false,
                    totalCount: res.data.pageinfo.totalCount
                })
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.props.history.push(RC_LOAD_LOGIN);

                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            this.setState({ isGetting: false })
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    renderCdrBalance() {
        const { tableData } = this.state
        var nullData = (
            <div className="ml-4 mt-2">
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0 entries</div>
                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
            </div>
        )
        if (tableData === null) {
            return nullData
        }
        else {
            return tableData.map((row, idx) => {
                return (
                    <tr key={idx} >
                        <td>{row.setuptime}</td>
                        <td>{row.type}</td>
                        <td>{row.method}</td>
                        <td>{row.amount}</td>
                    </tr>
                )
            })
        }
    }

    render() {
        const { startDate, endDate, isGetting, isAddModal, activePage, itemsCount, totalCount, isStripeOpen, isTopoffChecked, amountCharged, minimumCharged, isSubmitTopoff } = this.state
        let addServer = {
            details: {
                info: "Paypal Payment",
                msg: <PaymentAdd close={this.closeAddModal} />
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeAddModal },
            cancelBtn: { label: "Cancel", action: this.closeAddModal }
        }

        let addStripe = {
            details: {
                info: "Stripe Payment",
                msg: <StripePayment close={this.closeStripeModal} />
            },
            confirmBtn: { label: "Update", action: '' },
            crossBtn: { action: this.closeStripeModal },
            cancelBtn: { label: "Cancel", action: this.closeStripeModal }
        }
        return (
            <div>
                <CustomModal
                    visibility={isAddModal}
                    customBodyCSS={"h20"}
                    modalType={"success"}
                    details={addServer.details}
                    showBtns={false}
                    cancelBtn={addServer.cancelBtn}
                    crossBtn={addServer.crossBtn}
                />
                <CustomModal
                    visibility={isStripeOpen}
                    customBodyCSS={"h20"}
                    modalType={"success"}
                    details={addStripe.details}
                    showBtns={false}
                    cancelBtn={addStripe.cancelBtn}
                    crossBtn={addStripe.crossBtn}
                    modalSize={'large'}
                />
                <div className="card card-body border-top-success border-bottom-success">
                    <div className='row'>
                        <div className="col-md-12 mb-3">
                            <div className="mr-auto">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="cc_li_c" onChange={this.handleTopoff} checked={isTopoffChecked} />
                                    <label class="custom-control-label mr-2" for="cc_li_c">{isTopoffChecked ? 'Enable Topoff' : 'Disable Top Off'}</label>
                                    <small id="emailHelp" className="form-text text-muted fs-13 " style={{display:'contents'}}>Auto TopOff keeps the balance at Threshold by auto charging the saved card by configured minimum amount</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Balance Threshold</label>
                                <input type="number" className="form-control" disabled={isTopoffChecked ? false : true}
                                    name='minimumCharged' value={minimumCharged} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Minimum amount to charge</label>
                                <input type="number" class="form-control" disabled={isTopoffChecked ? false : true} name='amountCharged' value={amountCharged} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group" style={{ marginTop: '2.1em' }}>
                                {isTopoffChecked ?
                                    <button type="submit" className="btn btn-light ml-3 " disabled={isSubmitTopoff ? true : false} onClick={this.createTopoffValue} >Save Enable<i class={isSubmitTopoff ? "icon-spinner2 spinner ml-1" : 'icon-checkmark2 ml-1'}></i></button>
                                    :
                                    <button type="submit" className="btn btn-light ml-3" disabled={isSubmitTopoff ? true : false} onClick={this.createTopoffValue.bind(this, 'disable')} >Save Disable<i class={isSubmitTopoff ? "icon-spinner2 spinner ml-1" : 'icon-checkmark2 ml-1'}></i></button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-body border-top-success border-bottom-success">
                    <div className="header-elements-inline mb-3">
                        <div className="ml-auto">
                            <button type="submit" className="btn btn-light " onClick={this.openStripeModal} >Stripe Payment <i class="fab fa-stripe ml-2"></i></button>
                            <button type="submit" className="btn btn-light ml-3" onClick={this.openAddModal} >Paypal Payment <i class="fab fa-paypal ml-2"></i></button>
                            <button type="submit" className="btn btn-light ml-3" onClick={this.gotoPaymentConfiguration} >Payment Configuration<i class="icon-credit-card ml-2"></i></button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between bg-light border rounded p-0 mb-4">
                        <div class="d-flex justify-content-start">
                            <div class=" py-2 px-3 rounded-left">
                                <div class="form-group ml-3">
                                    <label className="fs-14">Start Date</label>
                                    <div class="input-group">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => this.handleDate(date, "start")}
                                            showTimeSelect
                                            timeFormat="HH:mm:ss"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            className="reporting-theme-date w-27em"
                                            isClearable
                                            placeholderText="Add date"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div class=" border-left-1 border-white py-2 px-3">
                                <div class="form-group">
                                    <label className="fs-14">End Date</label>
                                    <div class="input-group">
                                        <DatePicker
                                            name="end"
                                            selected={endDate}
                                            onChange={date => this.handleDate(date, "end")}
                                            showTimeSelect
                                            timeFormat="HH:mm:ss"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                            className="reporting-theme-date w-27em"
                                            maxDate={new Date()}
                                            isClearable
                                            placeholderText="Add date"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div class=" border-left-1 border-white py-2 px-3 rounded-right">
                                <div className="form-group" style={{ marginLeft: '-2em', marginTop: '2.2em' }}>
                                    <button type="submit" className="btn btn-light " onClick={this.getCdrBalance} >Filter Type <i class="icon-paperplane ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">

                        <div className="datatable-scroll bg-light border" style={{ overflowX: 'auto' }}>
                            <table className="table datatable-basic dataTable no-footer " id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead>
                                    <tr className="bg-dark">
                                        <th>Setup Time</th>
                                        <th>Type</th>
                                        <th>Method</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isGetting ? <CustomLoader /> : this.renderCdrBalance()}
                                </tbody>
                            </table>
                        </div>
                        <div className="datatable-footer mt-1 ">
                            <div className="mt-3">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCount}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CdrBalanceTable)
