import React, { Component } from 'react'
import Container from '../components/common/application_container';
import { getUser } from '../actions/session';
import { Notifications } from '../components/common/notification';
import Axios from 'axios';
import CustomLoader from '../components/common/loader';
import { USER_PAYMENT_CRED_URL } from '../config/rest_endpoints';


class SuccessfulPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            User: getUser(),
            isGetting: false,
            msg: '',
            isErr:false
        }
    }

    componentDidMount() {
        this.tokenPaymentInfo()
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }
    
    tokenPaymentInfo() {
        const { User } = this.state
        var userId = User.id
        var url = window.location
        var token = new URLSearchParams(url.search).get('token')
        var playerId = new URLSearchParams(url.search).get('PayerID')
        var payload = {
            "token": token,
            "payerID": playerId
        }
        this.setState({ isGetting: true })
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${USER_PAYMENT_CRED_URL}/${userId}/payment/paypal`, payload, { headers }).then(res => {
            if (res) {
                this.setState({
                    isGetting: false,
                    msg: res.data.message,
                    isErr:false
                })
                console.log('res', res)
                Notifications.notify('Payment successful', "Success", "success");
                this.interval = setInterval(() => {
                    this.props.history.push('/cdr-balance')
                }, 7000)
            }
        }).catch(err => {
            Notifications.notify("Payment unsuccessful", "Error", "error");
            console.log('err :>> ', err);
            this.setState({
                isErr:true,
                isGetting:true
            })
            this.interval =  setInterval(() => {
                this.props.history.push('/cdr-balance')
            }, 5000)
        })

    }

    render() {
        const { msg, isGetting,isSuccessfull } = this.state
        let show = (
            <div class="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                <button type="button" class="close" data-dismiss="alert"><span>×</span></button>
                <span class="font-weight-semibold">Payment !</span> Your payment was successful <a href="#" class="alert-link"> alert message.</a> 
            </div>
        )
        return (
            <Container title="Payment Info" >
                {isGetting ? <CustomLoader /> : show}
            </Container>
        )
    }
}
export default SuccessfulPayment