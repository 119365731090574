import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Error404 from '../common/error404';
import App from '../../App';
import { RC_LOAD_APP, ACCOUNT_FILE, RC_LOAD_LOGIN, ADDITIONAL_ACCOUNT_INFO,INVOICE, CARRIER, SERVER_CONFIG, CDR_LOGS, CDR_BALANCE_LOGS, SUCCESS_PAYMENT_URL, STATS, REDIRECT_URL, MAKE_PAYMENT_METHOD, STRIPE_CARD_SUCCESSFULL, STRIPE_PAGE_SUCCESSFULL, USER_STATS } from './RouteConstants';
// import Accounts from '../Accounts/account';
import Login from '../Authentication/loginComp';
import ServerMain from '../../User-serverConfig/serverMain';
import CdrMain from '../../User-CdrSide/cdrMain';
import CdrBalanceLogs from '../../User-cdr-balance/cdrBalancelogs';
import SuccessfulPayment from '../../User-cdr-balance/successfullPayment';
import SavePaymentIndex from '../../User-cdr-balance/makeSavePayment/savePaymentIndex';
import StripeSuccessfull from '../../User-cdr-balance/stripeSetup/stripeSuccessful';
import StripeSuccessPage from '../../User-cdr-balance/stripeSetup/stripeSuccessPage';
import StatsIndex from '../stats';
import InvoiceIndex from '../invoice/index';
// import AdditionalAccountInfo from '../Additinal-Account-info/additionalAccount';
// import CarrierIndex from '../carrier';



class AllRoutes extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={RC_LOAD_LOGIN} component={Login} />
                    <ProtectedRoute exact path={RC_LOAD_APP} component={App} />
                    <ProtectedRoute exact path={SERVER_CONFIG} component={ServerMain} />
                    <ProtectedRoute exact path={CDR_LOGS} component={CdrMain} />
                    <ProtectedRoute exact path={CDR_BALANCE_LOGS} component={CdrBalanceLogs} />
                    <ProtectedRoute exact path={SUCCESS_PAYMENT_URL} component={SuccessfulPayment} />
                    <ProtectedRoute exact path={`${MAKE_PAYMENT_METHOD}/:id`} component={SavePaymentIndex} />
                    <ProtectedRoute exact path={STRIPE_CARD_SUCCESSFULL} component={StripeSuccessfull} />
                    <ProtectedRoute exact path={STRIPE_PAGE_SUCCESSFULL} component={StripeSuccessPage} />
                    <ProtectedRoute exact path={USER_STATS} component={StatsIndex} />
                    <ProtectedRoute exact path={INVOICE} component={InvoiceIndex} />
                    {/* <ProtectedRoute exact path={ACCOUNT_FILE} component={Accounts} />
                    <ProtectedRoute exact path={CARRIER} component={CarrierIndex} />
                    <ProtectedRoute Route path={`${ADDITIONAL_ACCOUNT_INFO}/:id/:userNumber`} component={AdditionalAccountInfo} /> */}

                    <ProtectedRoute path="*" component={Error404} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default AllRoutes