import axios from 'axios';
import { LOGIN_URL, USER_LOGIN_URL } from "../config/rest_endpoints";
import { Notifications } from '../components/common/notification';
import { DATA_KEY } from './globalKeys';
import { getUser } from './session';
import { ADDITIONAL_ACCOUNT_INFO, ACCOUNT_FILE, STRIPE_CARD_SUCCESSFULL, STRIPE_PAGE_SUCCESSFULL, USER_STATS, INVOICE } from '../components/routers/RouteConstants';
export const ACTIVE_USER = "save_user";



/**
 * Validate the user
 * ----------------------
 * 
 * @param {*} loginDetails : {username, password}
 */
export function login(loginDetails,token) {
    console.log(`loginDetails`, loginDetails,token)
    let error = () => Notifications.notify("Unable to Authorized User", "error", "error");
     if('id' in loginDetails) {
         console.log('1 index  :>> ');
        return function(dispatch) {
            let res = {
                ...loginDetails,
                "permissions": `/,/server,/cdr-call,/cdr-balance,/successfull-payment,/other-stats,/payment-method,${INVOICE},${STRIPE_CARD_SUCCESSFULL},${STRIPE_PAGE_SUCCESSFULL},${USER_STATS}`,
                "token": token
            }
            res = { [DATA_KEY]: res }
            dispatch({
                type: ACTIVE_USER,
                payload: res
            })
        }
    }
    else {
        console.log('2 index  :>> ');
        return function (dispatch) {
            let body = {
                email: loginDetails.username,
                password: loginDetails.password
            }
            let header = { headers: { 'Content-Type': 'application/json' } }
            let error = () => Notifications.notify("Unable to Authorized User", "error", "error");

            axios.post(USER_LOGIN_URL, body, header).then(res => {
                console.log('res :>> ', res);
                let token = res.headers['x_auth_token'];
                if (res.status === 200) {
                    res = {
                        ...res.data.data,
                        "permissions": `/,/server,/cdr-call,/cdr-balance,/successfull-payment,/other-stats,/payment-method,${INVOICE},${STRIPE_CARD_SUCCESSFULL},${STRIPE_PAGE_SUCCESSFULL},${USER_STATS}`,

                        "token": token
                    }
                    res = { [DATA_KEY]: res }
                    dispatch({
                        type: ACTIVE_USER,
                        payload: res
                    })
                }
                else {
                    error()
                }
            }).catch(err => {
                const errMsg = err.response.data
                Notifications.notify(errMsg.message, "error", "error");
            })
        }
    }
    // return function (dispatch) {
    //         let body = {
    //             email: loginDetails.username,
    //             password: loginDetails.password
    //         }
    //         let header = { headers: { 'Content-Type': 'application/json' } }
    //         let error = () => Notifications.notify("Unable to Authorized User", "error", "error");

    //         axios.post(USER_LOGIN_URL, body, header).then(res => {
    //             console.log('res :>> ', res);
    //             let token = res.headers['x_auth_token'];
    //             if (res.status === 200) {
    //                 res = {
    //                     ...res.data.data,
    //                     "permissions": `/customer,/server,/cdr-call,/cdr-balance,/successfull-payment,/other-stats`,

    //                     "token": token
    //                 }
    //                 res = { [DATA_KEY]: res }
    //                 dispatch({
    //                     type: ACTIVE_USER,
    //                     payload: res
    //                 })
    //             }
    //             else {
    //                 error()
    //             }
    //         }).catch(err => {
    //             const errMsg = err.response.data
    //             Notifications.notify(errMsg.message, "error", "error");
    //         })
    //     }
    // if('id' in loginDetails) {
    //     // return function(dispatch) {
    //     //     let res = {
    //     //         ...loginDetails,
    //     //         "permissions": `/customer,/server,/cdr-call,/cdr-balance,/successfull-payment,/other-stats`,

    //     //         "token": loginDetailstoken
    //     //     }
    //     //     res = { [DATA_KEY]: res }
    //     //     dispatch({
    //     //         type: ACTIVE_USER,
    //     //         payload: res
    //     //     })
    //     // }

    // }
    // else {
    //     return function (dispatch) {
    //         let body = {
    //             email: loginDetails.username,
    //             password: loginDetails.password
    //         }
    //         let header = { headers: { 'Content-Type': 'application/json' } }
    //         let error = () => Notifications.notify("Unable to Authorized User", "error", "error");

    //         axios.post(USER_LOGIN_URL, body, header).then(res => {
    //             console.log('res :>> ', res);
    //             let token = res.headers['x_auth_token'];
    //             if (res.status === 200) {
    //                 res = {
    //                     ...res.data.data,
    //                     "permissions": `/customer,/server,/cdr-call,/cdr-balance,/successfull-payment,/other-stats`,

    //                     "token": token
    //                 }
    //                 res = { [DATA_KEY]: res }
    //                 dispatch({
    //                     type: ACTIVE_USER,
    //                     payload: res
    //                 })
    //             }
    //             else {
    //                 error()
    //             }
    //         }).catch(err => {
    //             const errMsg = err.response.data
    //             Notifications.notify(errMsg.message, "error", "error");
    //         })
    //     }
    // }
    
}



/**
 *  Get the user Details
 * ------------------------
 * 
 */
export function fetchUser() {
    return function (dispatch) {
        let res = getUser()
        res = { [DATA_KEY]: res }

        dispatch({
            type: ACTIVE_USER,
            payload: res
        })
    }
}