import React, { Component } from 'react'
import Select from 'react-select';
import { getUser } from '../actions/session';
import Axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomLoader from '../components/common/loader';
import moment from 'moment'
import Pagination from "react-js-pagination";
import { Notifications } from '../components/common/notification';
import { USER_CALL_LOGS_URL } from '../config/rest_endpoints';
import { RC_LOAD_LOGIN } from '../components/routers/RouteConstants';
import { Redirect } from 'react-router';



var firstDate = new Date();
const startT = moment(firstDate.getTime()).subtract(1, 'h')
//  var  fDate =  Math.floor(startT / 1000.0)
// console.log('fDate :>> ', fDate);
class CdrTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            User: getUser(),
            isGetting: false,
            startDate: startT._d,
            endDate: new Date(),
            selectedtype: { value: 'all', label: 'All' },
            callTypes: [
                { value: 'all', label: 'All' },
                { value: 'successful', label: 'Successfull' },
                { value: 'failed', label: 'Failed' }
            ],
            msg: '',
            searchValue: '',
            tableData: [],
            activePage: 1,
            itemsCount: 10,
            totalCount: 0,
            isLogOut:false
        }
        this.handleCallType = this.handleCallType.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.getCallLog = this.getCallLog.bind(this)
        this.searchSpace = this.searchSpace.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        this.getCallLog()
    }

    handleCallType(selectedtype) {
        this.setState({ selectedtype }, () => {
            console.log(selectedtype);
        })
    }

    searchSpace(e) {
        this.setState({ searchValue: e.target.value })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber
        }, () => {
            this.getCallLog()
        });
    }

    handleDate(date, value) {
        if (value == "start") {
            this.setState({ startDate: date }, () => {
                console.log('start', this.state.startDate);
            })
        } else {
            this.setState({ endDate: date }, () => {
                console.log('end', this.state.endDate);
            })
        }
    }

    getCallLog() {
        const { User, startDate, endDate, selectedtype, searchValue, itemsCount, activePage } = this.state
        var userID = User.id
        var payLoad = {
                "startDate": Math.floor(startDate.getTime() / 1000.0),
                "endDate": Math.floor(endDate.getTime() / 1000.0),
                "destination": searchValue
        }
        console.log(`payLoad`, payLoad)
        this.setState({ isGetting: true })
        var headers = { "Authorization": "Bearer " + User.token };
        Axios.post(`${USER_CALL_LOGS_URL}/${userID}/billing/reports/cdrs?page=${activePage}&limit=${itemsCount}&type=${selectedtype.value}`, payLoad, { headers }).then(res => {
            if (res) {
                console.log('resp :>> ', res);
                var resp = res.data
                this.setState({
                    tableData: resp.data,
                    totalCount: resp.pageinfo.totalCount,
                    isGetting: false
                })
            }
        }).catch(err => {
            const alert = err.response
            console.log(`alert.data.message`, alert.data.message)
            if (alert.data.message === 'invalid or expired jwt') {
                localStorage.removeItem('user');
                this.setState({isLogOut:true,})
                Notifications.notify(alert.data.message, "Token Expire", "error")
            }
            this.setState({ isGetting: false })
            Notifications.notify(alert.data.message, "Failed", "error")
        })
    }

    renderCallLogs() {
        const { tableData } = this.state
        var nullData = (
            <div className="ml-4 mt-2">
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0 entries</div>
                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
            </div>
        )
        if (tableData == null) {
            return nullData
        }
        else {
            return tableData.map((row, idx) => {
                return (
                    <tr key={idx} >
                        <td>{row.callID}</td>
                        <td>{row.setupTime}</td>
                        <td>{row.answerTime}</td>
                        <td>{row.callSetupTime}</td>
                        <td>{row.source == "" ? "N/A" : row.source}</td>
                        <td>{row.destination}</td>
                        <td>{row.customerIP}</td>
                        <td>{row.hangupCode}</td>
                        <td>{row.hangupCause}</td>
                        <td>{row.duration}</td>
                        <td>{row.billed}</td>
                    </tr>
                )
            })
        }
    }


    render() {
        const { callTypes, isGetting, selectedtype, startDate, endDate, searchValue, activePage, itemsCount, totalCount, isLogOut } = this.state
        if(isLogOut) {
            return <Redirect to={RC_LOAD_LOGIN} />
        }
        return (
            <div>
                <div className="card card-body border-top-success border-bottom-success">
                    <div className="card-header header-elements-inline">
                        {/* <h3 className="card-title font-weight-bold">Call Record</h3> */}
                    </div>
                    <div class="d-flex justify-content-start bg-light border rounded p-0 mb-4">
                        <div class=" py-2 px-3 rounded-left">
                            <div class="form-group">
                                <label className="fs-14">Start Date</label>
                                <div class="input-group">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => this.handleDate(date, "start")}
                                        showTimeSelect
                                        timeFormat="HH:mm:ss"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                                        className="reporting-theme-date w-27em"
                                         isClearable
                                        placeholderText="Add date"
                                    >

                                    </DatePicker>
                                   
                                </div>
                            </div>
                        </div>
                        <div class=" border-left-1 border-white py-2 px-3">
                            <div class="form-group">
                                <label className="fs-14">End Date</label>
                                <div class="input-group">
                                    <DatePicker
                                        name="end"
                                        selected={endDate}
                                        onChange={date => this.handleDate(date, "end")}
                                        showTimeSelect
                                        timeFormat="HH:mm:ss"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                                        className="reporting-theme-date w-27em"
                                          isClearable
                                       placeholderText="Add date"
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div class=" border-left-1 border-white py-2 px-3 rounded-right">
                            <div className="form-group" style={{ marginLeft: '-2em', marginTop: '2.2em' }}>
                                <button type="submit" className="btn btn-light " onClick={this.getCallLog} >Filter Date <i class="icon-calendar22 ml-2"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-start bg-light border rounded p-0 mb-4">
                        <div class=" py-2 px-3 rounded-left">

                            <div className="form-group ">
                                <label className="fs-14">Destination</label>
                                <div class="input-group width-27x">
                                    <input type="text" class="form-control " value={searchValue} onChange={this.searchSpace} placeholder="Destination" />
                                   
                                </div>
                            </div>
                        </div>
                        <div class=" border-left-1 border-white py-2 px-3">
                            <div className="form-group">
                                <label className="fs-14 ">Type</label>
                                <Select
                                    value={selectedtype}
                                    onChange={this.handleCallType}
                                    options={callTypes}
                                    className="width-27x"
                                />
                            </div>
                        </div>
                        <div class=" border-left-1 border-white py-2 px-3 rounded-right">
                            <div className="form-group" style={{ marginLeft: '-2em', marginTop: '2.2em' }}>
                                <button type="submit" className="btn btn-light " onClick={this.getCallLog} >Filter Type <i class="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                    </div>


                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">

                        <div className="datatable-scroll bg-light border" style={{ overflowX: 'auto' }}>
                            <table className="table datatable-basic dataTable no-footer " id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead>
                                    <tr className="bg-dark">
                                        <th>Call ID</th>
                                        <th>Start Time</th>
                                        <th>Answer Time</th>
                                        <th>Setup Time</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Customer IP</th>
                                        <th>Hangup Code</th>
                                        <th>Hangup Cause</th>
                                        <th>Duration</th>
                                        <th>Billed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isGetting ? <CustomLoader /> : this.renderCallLogs()}
                                </tbody>
                            </table>
                        </div>
                        <div className="datatable-footer mt-1">
                            <div className="mt-3">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCount}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default CdrTable