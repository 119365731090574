import React from 'react'
import { Line } from 'react-chartjs-2'

const LineGraph = (props) => {
    let { title, title1, title2, x, y, y1, y2, width, height } = props

    const dataSets = {
        labels: x,
        datasets: [
            {
                label: title,
                fill: false,
                lineTension: 0.4,
                backgroundColor: '#43a047',
                borderColor: '#43a047',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                data: y
            },
            {
                label: title1,
                fill: false,
                lineTension: 0.4,
                backgroundColor: '#03a9f4',
                borderColor: '#03a9f4',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                data: y1
            },
            {
                label: title2,
                fill: false,
                lineTension: 0.4,
                backgroundColor: '#e53935',
                borderColor: '#e53935',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                data: y2
            },
            
        ]
    };

    var options = {
        scales: {
            yAxes: [{
                stacked: false,
                ticks: {
                    beginAtZero: true,
                    fontSize:12
                },
            }],
            xAxes: [{
                stacked: false,
                ticks: {
                    fontSize:10
                },
            }]
        },
        legend: {
            position: 'bottom',
            display: true,
            labels: {
                boxWidth: 25,
                fontColor: 'black'
            }
        },
        responsive: true
    };

    return (
        <Line width={width}
            height={height}
            data={dataSets}
            options={options}
        />
    )

}
export default LineGraph