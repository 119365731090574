const PROTOCOL = 'https';
const SERVER_IP = 'std.directccs.com';
//  const SERVER_IP = '207.38.67.25';

// const SERVER_IP = 'service.longbranch.tech';
const PORT = '8080';
// const PORT = '3001'

export const SERVER_PATH = `${PROTOCOL}://${SERVER_IP}:${PORT}`;
export const ROOT_URL = `${SERVER_PATH}`;


// User Login url
export const USER_LOGIN_URL = `${ROOT_URL}/apiv1/login`

// Main page
export const USER_DETAILS_URL = `${ROOT_URL}/user/apiv1/stats/calls-balance`

// Server Listing
export const SERVER_LISTING_URL = `${ROOT_URL}/apiv1/account`

// Server Create 
export const USER_CREATE_SERVER_URL = `${ROOT_URL}/apiv1/account`

// Server Delete 
export const USER_DELETE_SERVER_URL = `${ROOT_URL}/apiv1/account`

// Server Update 
export const USER_UPDATE_SERVER_URL = `${ROOT_URL}/apiv1/account`

// Call Logs
export const USER_CALL_LOGS_URL = `${ROOT_URL}/apiv1/account`


// Balance Logs
export const USER_Balance_LOGS_URL = `${ROOT_URL}/apiv1/account`

// Create payment
export const USER_CREATE_PAYMENT_URL = `${ROOT_URL}/apiv1/account`
export const USER_CREATE_PAYMENT_STRIPE = `${ROOT_URL}/apiv1/account`

// Get Token from Payment
export const USER_PAYMENT_CRED_URL = `${ROOT_URL}/apiv1/account`

//Call Balance Stats
export const CALL_BALANCE_STATS_URL = `${ROOT_URL}/apiv1/reporting/account`

//Cdr Stats  
export const CDR_STATS_URL = `${ROOT_URL}/apiv1/reporting/account`

export const TIMELINE_STATS = `${ROOT_URL}/apiv1/reporting/week/account`
export const OTHER_STATS = `${ROOT_URL}/apiv1/reporting/history/account`
export const OUTBOUND_STATS_API = `${ROOT_URL}/apiv1/reporting/outbound/byday/account`
export const INBOUND_STATS_API = `${ROOT_URL}/apiv1/reporting/inbound/byday/account`
export const DID_STATS_API = `${ROOT_URL}/apiv1/reporting/dids/account`

// /reporting/history/user/:ID/stats






// Login url
//post
export const LOGIN_URL = `${ROOT_URL}/login`

// For Main Page
//get
export const USER_COUNT = `${ROOT_URL}/account/count`
//get
export const CALL_COUNT = `${ROOT_URL}/callcount`

// For Account 
//post
export const ACCOUNT_CREATED = `${ROOT_URL}/account`
//get
export const ACCOUNT_LISTING = `${ROOT_URL}/account`
//update user
export const Account_Update = `${ROOT_URL}/account`
//change status
export const Account_STATUS_CHANGE = `${ROOT_URL}/account`

// For Add_additonal_info_account
//For Billing
//post
export const BILLING_CREATED = `${ROOT_URL}/account`
//get
export const BILLING_LISTING = `${ROOT_URL}/account`

//ServerConnfig
//post
export const SERVER_CREATED = `${ROOT_URL}/account`
//get
export const SERVER_LISTING = `${ROOT_URL}/account`

export const DELETE_SERVER_URL = `${ROOT_URL}/account`

//Call_logs
//post

//get
export const CALL_LOG_LISTING = `${ROOT_URL}/account`

//Balance ADD
//post
export const BALANCE_ADD = `${ROOT_URL}/account`
//get

export const ACCOUNT_ACTIVITY_URL = `${ROOT_URL}/account`

export const CARRIER_UPDATE = `${ROOT_URL}/account`
export const CARRIER_LISTING = `${ROOT_URL}/account`

export const SERVER_TOGGAL = `${ROOT_URL}/account`
export const CARRIER_TOGGAL = `${ROOT_URL}/account`

// Stats 
export const CARRIER_STATS = `${ROOT_URL}/carrierstats`
export const ACCOUNT_STATS = `${ROOT_URL}/userstats`

export const MULTI_STATS_LINE_GRAPH = `${ROOT_URL}/apiv1/reporting/week/account`

/**
 * For user side login setting
 */
 
 export const USER_LOGIN_REDIRECT = `${ROOT_URL}/apiv1/account/login`
 export const CLIENT_ID = `${ROOT_URL}/apiv1/account/payment/stripe/setupintent`
 export const CLIENT_SAVE = `${ROOT_URL}/apiv1/account/payment/stripe/methods`
export const CLIENT_CHARGE = `${ROOT_URL}/apiv1/account/payment/stripe/paymentintent`
export const CLIENT_AUTO_TOPOFF = `${ROOT_URL}/apiv1/account/payment/stripe/paymentmethod`
export const GET_TOPOFF_PAYMENT = `${ROOT_URL}/apiv1/account/payment/retrieve/topoff`
export const CREATE_TOPOFF_PAYMENT = `${ROOT_URL}/apiv1/account/payment/topoff`
export const CLIENT_DELETE = `${ROOT_URL}/apiv1/account/payment/stripe/methods`


export const INBOUNT_CALL_COUNT = `${ROOT_URL}/apiv1/reporting/account/inbound`
export const INVOICE_LIST_API = `${ROOT_URL}/apiv1/invoice/account/`
export const INVOICE_DOWNLOAD_API = `${ROOT_URL}/invoice`

export const DOWNLOAD_CSV_FILE = `${ROOT_URL}/apiv1/account/ratedeck/download`
